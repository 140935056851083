import { message } from 'antd';
import Endpoints from 'configs/custom/Endpoints';
import axios from 'axios';

const ModificaronFormulario = ({ estatus, data, form }) => {
    if (estatus === 'nuevo') {
        return form.isFieldsTouched()
    } else if (JSON.stringify(data) === JSON.stringify(form.getFieldsValue(true))) {
        return false
    } else {
        return true
    }
}

const GuardarDatos = async ({ data, api, Id, method, token, cambiarCargando, cambiarErrores }) => {
    cambiarCargando(true);
    const alertaEnProceso = message.loading('Procesando la información...', 0)
    let idNuevo = false

    await axios({
        url: `${Endpoints.baseApi}/${api}/${Id}`,
        method: method,
        headers: { 'Authorization': `Bearer ${token}` },
        data: { data }
    })
        .then(res => {
            // console.log(respuestaAxios);
            alertaEnProceso()
            message.success('Guardado con Exito')
            cambiarCargando(false);
            const respuestaAxios = res.data;
            idNuevo = respuestaAxios?.data?.id
        })
        .catch(err => {
            const { details, status } = err?.response?.data?.error
            alertaEnProceso()
            if (status > 500) {
                message.error('Ocurrió un problema en el servidor')
            } else {
                const ListaErrores = []
                details?.errors?.forEach(element => {
                    ListaErrores.push(element.path[0])
                })
                if (ListaErrores.length !== 0) {
                    window.scrollTo(0, 0);
                    message.error('Verifica los campos en rojo', 5)
                }
                cambiarErrores(ListaErrores)
            }
            cambiarCargando(false);
            // throw new Error()
        })

    return idNuevo
}


export {
    GuardarDatos,
    ModificaronFormulario
};