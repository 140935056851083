import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useAuth } from 'contexts/AuthContext';
import { Dropdown, Avatar, Space, Button, Typography } from 'antd';
import MenuDesplegable from 'configs/layout/components/header/MenuDesplegable';

const BotonMenu = () => {
    const { usuario } = useAuth();

    return (
        <Dropdown overlay={<MenuDesplegable />} placement="bottomRight" arrow>
            <Button type="text">
                <Space size="middle">
                    <Typography.Text style={{ width: 120, maxWidth: '20vw' }} ellipsis={true}>
                        {usuario?.username}
                    </Typography.Text>
                    <Space size="small">
                        <Avatar shape="square" size="middle">{usuario?.username?.charAt(0)}</Avatar>
                        <DownOutlined style={{ fontSize: 12 }} />
                    </Space>
                </Space>
            </Button>
        </Dropdown>
    )
}

export default BotonMenu