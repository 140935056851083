import { message } from 'antd';
import axios from 'axios';
import Endpoints from 'configs/custom/Endpoints';

const FuncionMostrarAlertaEstatus = ({ file }) => {
    switch (file.status) {
        case 'done':
            return message.success(`${file.name} fue subido con éxito.`)
        case 'error':
            return message.error(`${file.name} no logró subirse con éxito.`)
        case 'removed':
            return message.info(`${file.name} fue retirado con éxito.`)

        default:
            return
    }
}

const LlamarApiBorrarArchivo = async ({ id, token }) => {
    await axios({
        url: `${Endpoints.baseApi}/upload/files/${id}`,
        method: 'delete',
        headers: { 'Authorization': `Bearer ${token}`, "content-type": 'multipart/form-data;' }
    })
        .then(res => {
            return true
        })
        .catch(err => {
            console.log(err);
            return false
        })
}

const BuscarArchivoEliminar = ({ id, token }) => {
    if (id) {
        LlamarApiBorrarArchivo({ id, token })
    }
}

const GuardarArchivos = async (event) => {
    const { file, onError, onSuccess, onProgress, headers } = event
    const data = new FormData()
    data.append('files', file)
    onProgress({ percent: 50 })
    await axios({
        url: `${Endpoints.baseApi}/upload`,
        method: 'post',
        headers: headers,
        data
    })
        .then(res => {
            const respuestaAxios = res.data;
            onSuccess(respuestaAxios)
        })
        .catch(err => {
            console.log(err);
            onError()
        })
}

const ActualizarValoresDelForm = (e) => {
    const NuevaLista = e && e.fileList;

    if (e?.file?.status === "done") {
        e.fileList.forEach((archivo, index) => {
            if (archivo.name === e.file.name && archivo.uid === e.file.uid) {
                // console.log(e.file.response[0]);
                NuevaLista[index] = e.file.response[0]
            }
        });
    }

    return NuevaLista
};

export { FuncionMostrarAlertaEstatus, BuscarArchivoEliminar, GuardarArchivos, ActualizarValoresDelForm }