// ! IMPORTANTE CHECAR ESTAS OPCIONES PARA LOGRAR EL MEJOR EQUILIBRIO DOCS: https://swr.vercel.app/es-ES/docs/options

const Swr = {
    refreshInterval: 180000,
    shouldRetryOnError: true,
    // revalidateIfStale: false,
    revalidateOnFocus: false,
    // revalidateOnReconnect: false
}

export default Swr