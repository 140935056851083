import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useObtenerEstatus = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let existEstatus = location?.state?.estatus ? location?.state?.estatus : 'nuevo'
    const origenRef = useRef(location?.state?.origen ? location?.state?.origen : false)
    const [estatus, cambiarEstatus] = useState(existEstatus)

    useEffect(() => {
        const ActualizarState = () => {
            navigate(location.pathname + location.search, { replace: true, state: { estatus, origen: origenRef.current } })
        }
        return ActualizarState();
    }, [estatus])

    return { estatus, cambiarEstatus }
}

export default useObtenerEstatus;
