import React from 'react';
import { PageHeader, Tag, Button } from 'antd';
import useActualizarTag from 'core/form/elements/hooks/useActualizarTag';
import ConfirmarSalir from 'core/form/elements/ConfirmarSalir';
import { ArrowLeftOutlined } from '@ant-design/icons';

const HeaderFormulario = ({ title, cambiarEstatus, estatus, cargando, ModificaronFormulario }) => {
    const { tag } = useActualizarTag({ estatus, cargando })

    return (
        <>
            <PageHeader
                tags={<Tag icon={tag.icon} color={tag.color} >{tag.title}</Tag>}
                title={title}
                backIcon={
                    <ConfirmarSalir ModificaronFormulario={ModificaronFormulario} placement="top">
                        <ArrowLeftOutlined />
                    </ConfirmarSalir>
                }
                onBack={() => null}
                extra={
                    <Button type="primary" disabled={estatus === 'duplicado' ? true : false} htmlType="button" onClick={() => cambiarEstatus('duplicado')} >
                        Duplicar
                    </Button>
                }
            />
        </>
    );
}

export default HeaderFormulario;