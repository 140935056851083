import React, { useState } from 'react';
import { Form, Button, Row, Col, Alert } from 'antd';
import { InputTexto } from 'core/other/Inputs';
import { useAuth } from 'contexts/AuthContext';
import Spinner from 'core/other/Spinner';
import styled from 'styled-components';
import { ModificarUsuario } from 'functions/FuncUsuario';

const FormularioCuenta = () => {
    const { usuario, cambiarUsuario } = useAuth();
    const [cargando, cambiarCargando] = useState(false);

    const handleOnFinish = (data) => {
        ModificarUsuario({ data, usuario, cambiarCargando, cambiarUsuario })
    }

    return (
        <Row gutter={24}>
            <Col {...Sizes['formulario']} xs={{ order: 2 }} lg={{ order: 1 }}>
                <Spinner cargando={cargando} transparent={true}>
                    <Form layout="vertical" hideRequiredMark onFinish={handleOnFinish} initialValues={usuario} >
                        <Row gutter={24}>
                            <InputTexto {...config['username']} />
                            <InputTexto {...config['email']} />
                        </Row>
                        <Button type="primary" htmlType="submit">
                            Guardar
                        </Button>
                    </Form>
                </Spinner>
            </Col>
            <Col {...Sizes['otros']} xs={{ order: 1 }} lg={{ order: 2 }}>
                <Alert message="Cambiar tu correo" type="info" showIcon description="Para cambiar el correo de tu cuenta, es necesario que pidas ayuda al administrador del sistema." />
            </Col>
        </Row>
    );
}

export default FormularioCuenta;

const Sizes = {
    formulario: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 8
    },
    otros: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 9
    },
}

const config = {
    username: {
        name: 'username',
        label: 'Nombre Completo',
        size: 'full',
        rules: [
            { required: true, message: 'Campo requerido.' },
            { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
        ],
    },
    email: {
        name: 'email',
        label: 'Correo (Email)',
        size: 'full',
        configElement: {
            disabled: true,
        },
        rules: [
            { required: true, message: 'Campo requerido.' },
            { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' },
            { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'El correo no es valido.' },
        ],
    }
}