import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Menu from 'configs/layout/components/Menu';

const { Sider } = Layout;

const Side = ({collapsed, changeCollapsed}) => {
    

    return (
        <Sider
            // breakpoint="xl" collapsedWidth="0"
            style={{
                overflow: 'auto',
                position: 'fixed',
                left: 0,
                top: 64,
                bottom: 0,
              }}
            width={250} collapsedWidth={80}
            collapsible collapsed={collapsed} onCollapse={() => changeCollapsed(!collapsed)}>
            <Contenedor>
                <Menu />
            </Contenedor>
        </Sider>
    );
}

export default Side;

const Contenedor = styled.div`
    background-color: #151515;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`