const FormatoDinero = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const FuncionPrecio = ({ cantidad, precio, descuento }) => FormatoDinero.format((precio * (1 - ((descuento ?? 0)/100))) * (cantidad ?? 1))

const optionsFormatoFecha = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

const FormatoFecha = (fecha) => {
    if (fecha.length > 10) {
        return new Date(fecha).toLocaleDateString('es', optionsFormatoFecha)
    } else {
        return new Date(fecha + 'T00:00:00').toLocaleDateString('es', optionsFormatoFecha)
    }
}

export { FuncionPrecio, FormatoFecha };