import React, { useState } from 'react';
import { Select, Col, Form, Row, Empty } from 'antd';
import useObtenerDatosSelect from 'hooks/useObtenerDatosSelect';
import Sizes from 'configs/custom/Sizes';
import Spinner from 'core/other/Spinner';

const ElementoCargandoSelect = () => (<Row justify="center"><Spinner cargando={true} transparent={true} delay={300} /></Row>)

const SelectDataRemote = ({ size, endpoint, camposBusqueda, vistaResultados, optSeleccionado, ...props }) => {
    const [busqueda, cambiarBusqueda] = useState('');
    const { data, isLoading } = useObtenerDatosSelect({ busqueda, camposBusqueda, endpoint, optSeleccionado })

    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <Select
                    filterOption={false}
                    showSearch
                    onSearch={cambiarBusqueda}
                    notFoundContent={isLoading ? <ElementoCargandoSelect /> : data.length === 0 ? <Empty /> : null}
                >
                    {
                        data && data.map((renglon, index) => {
                            return (
                                <Select.Option value={renglon.id} key={index}>{vistaResultados(renglon)}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </Col>
    )
}

const SelectDataLocal = ({ size, data, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <Select>
                    {
                        data && data.map((renglon, index) => {
                            return (
                                <Select.Option value={renglon.value} key={index}>{renglon.name}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </Col>
    )
}

export { SelectDataRemote, SelectDataLocal }