import React from 'react';
import { Menu } from 'antd';
import { EyeOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';

const MenuContextTable = ({ navigate, tipo, elementoSeleccionado, location }) => {
    if (elementoSeleccionado) {
        return (
            <Menu>
                <Menu.Item key="1" icon={<EditOutlined />} onClick={() => navigate(`/sistema/catalogo/${tipo}/captura?id=${elementoSeleccionado}`, { state: { estatus: 'editando', origen: location.pathname + location.search } })} >
                    Editar
                </Menu.Item>
                <Menu.Item key="2" icon={<EyeOutlined />} onClick={() => navigate(`/sistema/catalogo/${tipo}/busqueda?id=${elementoSeleccionado}`)} >
                    Ver
                </Menu.Item>
                <Menu.Item key="3" icon={<CopyOutlined />} onClick={() => navigate(`/sistema/catalogo/${tipo}/captura?id=${elementoSeleccionado}`, { state: { estatus: 'duplicado', origen: location.pathname + location.search } })} >
                    Duplicar
                </Menu.Item>
            </Menu>
        )
    } else {
        return <></>
    }
}

export default MenuContextTable