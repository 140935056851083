import React, { useState } from 'react';
import { Form, Button, Alert, Row, Col, message } from 'antd';
import { useAuth } from 'contexts/AuthContext';
import Spinner from 'core/other/Spinner';
import { SolicitarCodigoPassword, CambiarPassword } from 'functions/FuncUsuario';
import { InputTexto } from 'core/other/Inputs';
import styled from 'styled-components';

const FormularioPassword = () => {
    const { usuario, cambiarUsuario } = useAuth();
    const [cargando, cambiarCargando] = useState(false);
    const [form] = Form.useForm();

    const handleOnFinish = async (data) => {
        if (data.password !== data.passwordConfirmation) {
            return message.error('Las contraseñas ingresadas deben ser iguales', 5)
        } else {
            await CambiarPassword({ data, usuario, cambiarCargando, cambiarUsuario })
            .then(() => {
                form.resetFields();
            })
        }
    }

    return (
        <Row gutter={24}>
            <Col {...Sizes['formulario']} xs={{ order: 2 }} lg={{ order: 1 }}>
                <Spinner cargando={cargando} transparent={true} >
                    <Form form={form} layout="vertical" hideRequiredMark onFinish={handleOnFinish} initialValues={usuario} >
                        <Row gutter={24}>
                            <InputTexto {...config['code']} />
                            <InputTexto {...config['password']} />
                            <InputTexto {...config['passwordConfirmation']} />
                        </Row>
                        <Button type="primary" htmlType="submit">
                            Guardar
                        </Button>
                    </Form>
                </Spinner>
            </Col>
            <Col {...Sizes['otros']} xs={{ order: 1 }} lg={{ order: 2 }}>
                <AlertStyled message="Importante" type="warning" showIcon description="El código para cambiar tu contraseña es enviado a tu correo electrónico (Verifica en la carpeta SPAM / No Deseados)" />
                <Button type="danger" htmlType="button" block loading={cargando} onClick={() => SolicitarCodigoPassword({ usuario, cambiarCargando })}>
                    Solicitar Código
                </Button>
            </Col>
        </Row>
    );
}

export default FormularioPassword;

const AlertStyled = styled(Alert)`
    margin-bottom: 30px;
`
const Sizes = {
    formulario: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 8
    },
    otros: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 9
    },
}

const config = {
    code: {
        name: 'code',
        label: 'Código',
        tooltip: 'Ingresa el código recibido por correo.',
        size: 'full',
        rules: [
            { required: true },
            { min: 128 },
            { max: 128 }
        ],
    },
    password: {
        name: 'password',
        label: 'Nueva contraseña',
        size: 'full',
        rules: [
            { required: true },
            { min: 6 }
        ],
    },
    passwordConfirmation: {
        name: 'passwordConfirmation',
        label: 'Repite contraseña',
        size: 'full',
        rules: [
            { required: true },
            { min: 6 }
        ],
    },
}