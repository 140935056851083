const FuncionAcomodarData = (data) => {
    const ObjetoAcomodado = []

    if (data) {
        data?.forEach(renglon => {
            ObjetoAcomodado.push({
                id: renglon.id,
                ...renglon.attributes,
            });
        })
    }

    return ObjetoAcomodado
}

const FuncionAcomodarListaArrays = (data, ListaArray, ListaIds) => {

    let newData = {...data}

    ListaArray?.forEach(element => {
        newData[element] = FuncionAcomodarData(data?.[element]?.data)
    });

    ListaIds?.forEach(element => {
        newData[element] = data?.[element]?.data?.id
    });

    return newData
}

export { FuncionAcomodarData, FuncionAcomodarListaArrays }