import React from 'react';
import { Divider } from 'antd';
import { InputTexto, InputTextArea, InputDinero, InputFecha } from 'core/other/Inputs';
import { SelectDataRemote } from 'core/other/Selects';
import SubirArchivo from 'core/other/SubirArchivo';
import { ValidarObjetoVacio } from 'functions/FuncFiltros';

const FormularioMantenimiento = ({ data, errores }) => {
    
    const config = {
        modelo: {
            name: 'modelo',
            label: 'Modelo (Patterns)',
            tooltip: 'Selecciona un Modelo',
            size: 'middle',
            endpoint: 'modelos',
            camposBusqueda: ['nombre', 'identificador'],
            vistaResultados: (renglon) => `${renglon.identificador} - ${renglon.nombre}`,
            optSeleccionado: !ValidarObjetoVacio(data) && { ...data?.cliente?.data?.attributes, id: data?.cliente?.data?.id },
            validateStatus: errores?.includes('modelo') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        responsable: {
            name: 'responsable',
            label: 'Responsable (Maintenance Man)',
            tooltip: 'Ingresa el nombre del responsable del mantenimiento.',
            size: 'middle',
            validateStatus: errores?.includes('responsable') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true },
                { min: 5 },
            ],
        },
        costo: {
            name: 'costo',
            label: 'Costo (Cost)',
            tooltip: 'Ingresa el costo del pedimento',
            size: 'small',
            validateStatus: errores?.includes('costo') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        fecha: {
            name: 'fecha',
            label: 'Fecha (Date)',
            tooltip: 'Ingresa la fecha del pedimento',
            size: 'xsmall',
            validateStatus: errores?.includes('fecha') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        lugar: {
            name: 'lugar',
            label: 'Lugar (Place)',
            tooltip: 'Ingresa el lugar/area donde fue realizado el mantenimiento',
            size: 'middle',
            rules: [
                { required: true },
            ],
        },
        motivo: {
            name: 'motivo',
            label: 'Motivo del Mantenimiento (Reason for maintenance)',
            tooltip: 'Ingresa una descripción del Modelo',
            size: 'large',
            validateStatus: errores?.includes('motivo') ? 'error' : false,
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        fotos: {
            name: 'fotos',
            label: 'Fotos',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            size: 'large',

        },

    }

    return (
        <>
            <SelectDataRemote {...config['modelo']} />
            <InputTexto {...config['responsable']} />
            <InputDinero {...config['costo']} />
            <InputFecha {...config['fecha']} />

            <Divider />
            <InputTextArea {...config['motivo']} />
            <InputTexto {...config['lugar']} />

            <Divider />
            <SubirArchivo {...config['fotos']} />
        </>
    );
}

export default FormularioMantenimiento;