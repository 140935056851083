import { InputTexto } from 'core/other/Inputs';

const clientesFiltros = [
    {
        Element: InputTexto,
        name: 'nombre',
        label: 'Nombre',
        tooltip: 'Buscar por nombre del cliente',
        size: 'small',
        style: {
            marginBottom: 0,
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'correo',
        label: 'Correo',
        tooltip: 'Buscar por correo del cliente',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    }
]

const modelosFiltros = [
    {
        Element: InputTexto,
        name: 'identificador',
        label: 'No. Modelo',
        tooltip: 'Buscar por No. Modelo',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'nombre',
        label: 'Nombre del modelo',
        tooltip: 'Buscar por nombre del modelo',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'cliente',
        label: 'Cliente',
        tooltip: 'Buscar por nombre del cliente',
        size: 'small',
        style: {
            marginBottom: 0,
        },
        configElement: {
            allowClear: true,
        },
    },
    
]

const pedimentosFiltros = [
    {
        Element: InputTexto,
        name: 'numeroPedimento',
        label: 'No. Pedimento',
        tooltip: 'Buscar por No. Pedimento',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'clavePedimento',
        label: 'Clave',
        tooltip: 'Buscar por clave del pedimento',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'factura',
        label: 'Factura',
        tooltip: 'Buscar por factura',
        size: 'small',
        style: {
            marginBottom: 0,
        },
        configElement: {
            allowClear: true,
        },
    },
    
]

const mantenimientosFiltros = [
    {
        Element: InputTexto,
        name: 'responsable',
        label: 'Responsable',
        tooltip: 'Buscar por responsable del Mantenimiento',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },
    {
        Element: InputTexto,
        name: 'modelo',
        label: 'Modelo',
        tooltip: 'Buscar por nombre del modelo',
        size: 'small',
        style: {
            marginBottom: 0
        },
        configElement: {
            allowClear: true,
        },
    },    
]

export { clientesFiltros, modelosFiltros, pedimentosFiltros, mantenimientosFiltros }