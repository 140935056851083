import React from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import {Menu} from 'antd';
import { CerrarSesion } from 'functions/FuncUsuario';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const MenuDesplegable = () => {
    const { cambiarUsuario, cambiarCargando } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <Menu selectedKeys={pathname}>
            <ElementMenu key="/sistema/config/cuenta" icon={<UserOutlined />} onClick={() => navigate('/sistema/config/cuenta')} >
                Config. de Cuenta
            </ElementMenu>
            <ElementMenu key="2" icon={<LogoutOutlined />} className="red" onClick={() => CerrarSesion({ cambiarUsuario, cambiarCargando })}>
                Cerrar Sesión
            </ElementMenu>
        </Menu>
    )
}

export default MenuDesplegable;

const ElementMenu = styled(Menu.Item)`
    padding-top: 7px;
    padding-bottom: 7px;

    &.red {
        color: #fb6067;
        &:hover {
            color: #fff;
            background-color: #a61d24;
        }
    } 
`