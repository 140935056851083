import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Spinner = ({ children, cargando, transparent, delay}) => {

    const Icono = () => {
        return (
            <IconoPersonalizado transparent={transparent ?? false}>
                    <LoadingOutlined spin />
                    <p>Cargando...</p>
            </IconoPersonalizado>
        )
    }

    return (
        <ElementSpinStyled spinning={cargando} indicator={<Icono />} delay={delay ?? 0}>
            {children}
        </ElementSpinStyled>
    );
}

export default Spinner;

const ElementSpinStyled = styled(Spin)`
    max-height: fit-content!important;
`

const IconoPersonalizado = styled.div`
    height: 100%;
    /* border: 1px solid #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.transparent ? 'transparent' : '#ffffff11'};

    & span {
        font-size: 36px;
    }
    
    & p {
        font-size: 14px;
        margin-top: 15px;
    }

`