import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.dark.min.css'; //* Modo oscuro de ANTD
import { ConfigProvider } from 'antd'; //* Provider de ANTD
import es_ES from 'antd/lib/locale/es_ES'; //* Traducción de ANTD
import 'moment/locale/es-mx'; //* Traducción Fechas
import { SWRConfig } from 'swr' //* Provider de SWR
import Swr from 'configs/custom/Swr';

import App from 'App';
import { BrowserRouter } from "react-router-dom";

const Aplicacion = () => {
  return (
    <ConfigProvider locale={es_ES}>
      <BrowserRouter>
        <SWRConfig value={Swr}>
          <App />
        </SWRConfig>
      </BrowserRouter>
    </ConfigProvider>
  );
}

ReactDOM.render(<Aplicacion />, document.getElementById('root'));