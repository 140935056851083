import React from 'react';
import { Row, Space, Button, Dropdown, Menu } from 'antd';
import ConfirmarSalir from 'core/form/elements/ConfirmarSalir';
import styled from 'styled-components';

const BotonesFormulario = ({ ModificaronFormulario, form, handleOnFinish, cambiarErrores }) => {

    const FuncionGuardarYNuevo = async (accion) => {
        form.validateFields()
            .then(() => {
                let data = form.getFieldsValue(true)
                delete data['createdAt']
                delete data['updatedAt']
                handleOnFinish(data, accion)
            })
            .catch((err) => {
                const Errores = []
                err?.errorFields.forEach(element => {
                    Errores.push(element.name[0])
                });
                cambiarErrores(Errores);
            })

    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => FuncionGuardarYNuevo('nuevo')} >
                Guardar y nuevo
            </Menu.Item>
            <Menu.Item key="2" onClick={() => FuncionGuardarYNuevo('salir')}>
                Guardar y salir
            </Menu.Item>
        </Menu>
    );

    return (
        <RowEstilizado justify='end'>
            <Space>
                <ConfirmarSalir ModificaronFormulario={ModificaronFormulario} placement="topRight">
                    <Button type="primary" htmlType="button" danger>
                        Volver Atrás
                    </Button>
                </ConfirmarSalir>

                <Dropdown.Button type="primary" htmlType="submit" overlay={menu}>
                    Guardar
                </Dropdown.Button>

            </Space>
        </RowEstilizado>
    );
}

export default BotonesFormulario;

const RowEstilizado = styled(Row)`
    margin-top: 28px;
`