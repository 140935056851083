import React, { useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

const RutaPrivada = ({ children }) => {
    const { usuario } = useAuth();
    const { pathname } = useLocation();

    // * UseEffect usado para hacer un ScrollToTop cuando cambie la página.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (!usuario) {
        return <Navigate to="/login" />
    } else {
        return <>{children}</>
    }

}

export default RutaPrivada;