import React from 'react';
import { Divider, Modal } from 'antd';
import { InputTexto, InputList, InputDinero, InputFecha } from 'core/other/Inputs';
import { SelectDataRemote, SelectDataLocal } from 'core/other/Selects';
import SubirArchivo from 'core/other/SubirArchivo';
import { ValidarObjetoVacio } from 'functions/FuncFiltros';

const FormularioPedimento = ({ data, errores }) => {

    const config = {
        modelo: {
            name: 'modelo',
            label: 'Modelo (Patterns)',
            tooltip: 'Selecciona un Modelo',
            size: 'middle',
            endpoint: 'modelos',
            camposBusqueda: ['nombre', 'identificador'],
            vistaResultados: (renglon) => `${renglon.identificador} - ${renglon.nombre}`,
            optSeleccionado: !ValidarObjetoVacio(data) && { ...data?.cliente?.data?.attributes, id: data?.cliente?.data?.id },
            validateStatus: errores?.includes('modelo') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        numeroPedimento: {
            name: 'numeroPedimento',
            label: 'Número de Pedimento (ID Exp. Doc.)',
            tooltip: 'Ingresa el número del pedimento',
            size: 'middle',
            normalize: value => value.toUpperCase(),
            validateStatus: errores?.includes('numeroPedimento') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' },
                { pattern: /^([a-zA-Z0-9]{2}\s){2}[a-zA-Z0-9]{4}\s([a-zA-Z0-9]{7})$/g, message: 'Formato: ## ## #### ####### (Letras, Números y Especios en blanco)' }
            ],
        },
        costo: {
            name: 'costo',
            label: 'Costo de Pedimento (Cost)',
            tooltip: 'Ingresa el costo del pedimento',
            size: 'small',
            validateStatus: errores?.includes('costo') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        fecha: {
            name: 'fecha',
            label: 'Fecha (Date)',
            tooltip: 'Ingresa la fecha del pedimento',
            size: 'xsmall',
            validateStatus: errores?.includes('fecha') ? 'error' : false,
            rules: [
                { required: true },
            ],
        },
        factura: {
            name: 'factura',
            label: 'Factura (Invoice)',
            tooltip: 'Ingresa el folio de la factura',
            size: 'small',
            rules: [
                { required: true },
            ],
        },
        tipoOperacion: {
            name: 'tipoOperacion',
            label: 'Tipo de Operación (Movement Type)',
            tooltip: 'Tipo de Operación marcada en el pedimento',
            size: 'small',
            data: OptSelectTipoOperacion,
            rules: [
                { required: true },
            ],
        },
        clavePedimento: {
            name: 'clavePedimento',
            label: 'Clave del Pedimento (Key of Exp. Doc.)',
            tooltip: 'Ingresa la clave del Pedimento',
            size: 'small',
            normalize: (value) => {
                const newValue = value.toUpperCase()
                if (newValue === 'A1') {
                    Modal.warning({
                        title: '¡Cuidado!',
                        content: 'La Clave A1 es una importanción definitiva, si tienes dudas sobre este aviso solicita apoyo.',
                    })
                }
                return newValue
            },
            rules: [
                { required: true },
                { pattern: /^[a-zA-Z0-9]{2}$/, message: 'Formato: ## (Letras y Números)' },
            ],
        },
        regimen: {
            name: 'regimen',
            label: 'Regimen (Regime)',
            tooltip: 'Ingresa el regimen del Pedimento',
            size: 'small',
            rules: [
                { required: true },
            ],
        },
        gastos: {
            name: 'gastos',
            label: 'Gastos Extras (Extra Expenses)',
            size: 'middle',
            tooltip: 'Ingresa los gastos extras que ocurrieron durante el proceso de este pedimento',
            textoAgregar: 'Agregar Gasto',
            inputDinero: true,
        },
        fotos: {
            name: 'fotos',
            label: 'Fotos',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            size: 'large',

        },
        documentos: {
            name: 'documentos',
            // label: 'Documentos',
            listType: 'text',
            accept: 'application/pdf',
            size: 'middle',
        },

    }

    return (
        <>
            <SelectDataRemote {...config['modelo']} />
            <InputTexto {...config['numeroPedimento']} />
            <InputDinero {...config['costo']} />
            <InputFecha {...config['fecha']} />

            <Divider />
            <InputTexto {...config['factura']} />
            <SelectDataLocal {...config['tipoOperacion']} />
            <InputTexto {...config['clavePedimento']} />
            <InputTexto {...config['regimen']} />

            <Divider orientation="left"></Divider>
            <InputList {...config['gastos']} />
            <SubirArchivo {...config['documentos']} />
            <SubirArchivo {...config['fotos']} />

        </>
    );
}

export default FormularioPedimento;

const OptSelectTipoOperacion = [
    {
        value: 'EXP',
        name: 'Exportación'
    },
    {
        value: 'IMP',
        name: 'Importación'
    },
]