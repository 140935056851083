import React, { Fragment } from 'react';
import { Tag, Space, Tooltip } from 'antd';
import useObtenerFiltrosActivos from 'core/tables/elements/useObtenerFiltrosActivos';
import { EliminarFiltroUrl, EliminarTodosLosFiltros } from 'functions/FuncFiltros';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';


const TagsFiltrosActivos = ({ form }) => {
    const { filtrosActivos } = useObtenerFiltrosActivos()
    const { getFieldInstance } = form
    let [searchParams, setSearchParams] = useSearchParams();

    return (
        <Space>
            {
                filtrosActivos.length !== 0 && filtrosActivos.map((filtro, index) => {
                    return (
                        <Tooltip key={index} title="Eliminar el filtro" onClick={() => EliminarFiltroUrl(searchParams, setSearchParams, filtro.name)} >
                            <Tag color="lime" icon={<SearchOutlined />} style={{ cursor: 'pointer' }}>
                                {getFieldInstance(filtro?.name)?.input?.labels[0]?.innerText ?? filtro.name}: {filtro.value}
                            </Tag>
                        </Tooltip>
                    )
                })
            }
            {
                filtrosActivos.length !== 0 &&
                <Tooltip title="Eliminar todos los filtros" onClick={() => EliminarTodosLosFiltros(searchParams, setSearchParams)} >
                    <Tag color="red" icon={<DeleteOutlined />} style={{ cursor: 'pointer' }}>
                        Eliminar Filtros
                    </Tag>
                </Tooltip>
            }
        </Space>
    );
}

export default TagsFiltrosActivos;