import React from 'react';

const MapeoFormulario = ({ formulario }) => {
    return (
        <>
            {
                formulario?.map((renglon, index) => {
                    const { Element, ...restProps } = renglon
                    return (
                        <Element {...restProps} key={index} />
                    )
                })
            }
        </>
    );
}

export default MapeoFormulario;