import { useState, useEffect } from 'react';
import { SyncOutlined, PlusOutlined, EditOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const useActualizarTag = ({ estatus, cargando }) => {
    const [tag, cambiarTag] = useState(EstatusFormulario['cargando']);

    useEffect(() => {
        const ActualizarForm = () => {
            if (!cargando) { cambiarTag(EstatusFormulario[estatus]) } // else { cambiarTag(EstatusFormulario['cargando']) }
        }
        return ActualizarForm();

    }, [estatus, cargando])

    return { tag };
}

export default useActualizarTag



const EstatusFormulario = {
    cargando: {
        icon: <SyncOutlined spin />,
        title: 'Cargando...',
        color: 'processing'
    },
    nuevo: {
        icon: <PlusOutlined />,
        title: 'Nuevo',
        color: 'blue'
        // color: '#1765ad'
    },
    editando: {
        icon: <EditOutlined />,
        title: 'Editando',
        color: 'gold'
        // color: '#7c5914'
    },
    duplicado: {
        icon: <CopyOutlined />,
        title: 'Duplicado',
        color: 'volcano'
        // color: '#aa3e19'
    },
    error: {
        icon: <ExclamationCircleOutlined />,
        title: 'Ocurrio un error',
        color: 'red'
        // color: '#a61d24'
    }
}