import React, { useState } from 'react';
import { Card, Result, Row } from 'antd';
import HeaderView from 'core/view/elements/HeaderView';
import Spinner from 'core/other/Spinner';
import useValidarTipoUrl from 'core/custom/useValidarTipoUrl';
import { VistasConfig } from 'configs/custom/Vistas';
import useObtenerDatosVista from 'core/view/elements/useObtenerDatosVista';

const VerRegistros = () => {
    const [cargando, cambiarCargando] = useState(true)

    // Validamos que el tipo de la url sea uno de los válidos
    const { tipo } = useValidarTipoUrl({ ElementConfig: VistasConfig });
    // Extraemos la configuración del tipo de vista
    const { vistaNombre, vistaConfigHook, vistaConfigSelect, ElementosVista } = VistasConfig[tipo] ?? {}

    const { data } = useObtenerDatosVista({ vistaConfigHook, cambiarCargando })

    return (
        <Card>
            <HeaderView title={vistaNombre} vistaConfigSelect={vistaConfigSelect} />
            {
                cargando ? <ElementoCargando /> :
                    data ? <ElementosVista data={data} /> :
                        <Result
                            status={data === null ? 'info' : 'error'}
                            title={data === null ? `Selecciona un ${vistaNombre}` : 'Ocurrió un problema'}
                            subTitle={data === false && 'Si el problema persiste solicita apoyo al administrador del sistema.'}
                        />
            }
        </Card>
    );
}

export default VerRegistros;

const ElementoCargando = () => <Row justify="center"><Spinner cargando={true} transparent={true} /></Row>