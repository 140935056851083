import { useState, useEffect } from 'react'
import axios from 'axios';
import Endpoints from 'configs/custom/Endpoints';
import { useAuth } from 'contexts/AuthContext';
import qs from 'qs';
import { message } from 'antd';
import useFiltrosUrl from 'core/custom/useFiltrosUrl';

const useObtenerRegistro = ({ api, cambiarCargando }) => {
    const [data, cambiarData] = useState({});
    const { id, modificarFiltrosUrl, eliminarTodosLosFiltrosUrl } = useFiltrosUrl();
    const { token } = useAuth();
    
    useEffect(() => {
        const PeticionDatos = async () => {
            if (id) {
                cambiarCargando(true)
                const query = qs.stringify({ populate: '*' }, { encodeValuesOnly: true, });
                await axios({
                    method: 'get',
                    url: `${Endpoints.baseApi}/${api}/${id}?${query}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                })
                    .then(res => {
                        const { attributes } = res?.data?.data;
                        cambiarData({ ...attributes });
                        cambiarCargando(false)
                    })
                    .catch(err => {
                        console.log(err);
                        message.error('Si el problema continua, solicita apoyo al administrador');
                    })
            } else {
                cambiarData({})
                cambiarCargando(false)
            }
        }

        return PeticionDatos();
    }, [id, api, token, cambiarCargando])

    return { data, cambiarData, eliminarTodosLosFiltrosUrl, token, id, modificarFiltrosUrl }
}

export default useObtenerRegistro;