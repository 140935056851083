import React from 'react';
import { Row, Button, Space, Col } from 'antd';
import MapeoFormulario from 'core/custom/MapeoFormulario';

const FiltrosTabla = ({ tablaFiltros, loading }) => {

    return (
        <Row justify="space-between" gutter={24}>
            <MapeoFormulario formulario={tablaFiltros} />
            <Col flex="auto">
                <Row justify="end">
                    <Space>
                        <Button disabled={loading} type="text" danger htmlType="reset" >Vaciar</Button>
                        <Button disabled={loading} type="primary" htmlType="submit">Aplicar</Button>
                    </Space>
                </Row>
            </Col>
        </Row>
    );
}

export default FiltrosTabla;