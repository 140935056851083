import React from 'react';
import { Typography } from 'antd';

const NoDisponible = () => {
    return (
        <Typography.Text type="secondary">No Disponible</Typography.Text>
    );
}

export default NoDisponible;
