import React from 'react';
import { List, Space, Descriptions, Typography } from 'antd';
import styled from 'styled-components';
import { FuncionAcomodarData } from 'functions/FuncHooks';
import { Link, useLocation } from 'react-router-dom';

const VistaCliente = ({ data }) => {
    const { nombre, correo, modelos } = data
    let location = useLocation()

    return (
        <ContenedorSpace direction="vertical" size="large">
            <Descriptions size="small" column={{ xxl: 3, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} bordered>
                <Descriptions.Item label="Nombre">{nombre}</Descriptions.Item>
                <Descriptions.Item label="Correo">{correo}</Descriptions.Item>
                <Descriptions.Item label="Modelos">{modelos?.data?.length}</Descriptions.Item>
            </Descriptions>
            <List
                header={<Typography.Title level={4}>Lista de Modelos</Typography.Title>}
                itemLayout="horizontal"
                dataSource={FuncionAcomodarData(modelos?.data)}
                bordered
                pagination={true}
                renderItem={renglon => {
                    const { identificador, nombre, ubicacion, id } = renglon
                    return (
                        <List.Item
                            actions={[
                                <Link to={`/sistema/catalogo/modelos/busqueda?id=${id}`}>
                                    Ver
                                </Link>,
                                <Link to={`/sistema/catalogo/modelos/captura?id=${id}`} state={{ estatus: 'editando', origen: location.pathname + location.search }}>
                                    Editar
                                </Link>
                            ]}
                        >
                            <List.Item.Meta
                                title={`${identificador} - ${nombre}`}
                                description={`Ubicación: ${ubicacion ?? 'No Disponible'}`}
                            />
                        </List.Item>
                    )
                }}
            />
        </ContenedorSpace>
    );
}

export default VistaCliente;

const ContenedorSpace = styled(Space)`
    padding: 16px 24px;
    width: 100%;
`