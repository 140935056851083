import { useEffect } from 'react';
import { ValidarObjetoVacio } from 'functions/FuncFiltros';

const useActualizarForm = ({ form, data, cargando, cambiarEstatus, acomodarDatosMostrar, cambiarErrores }) => {
    
    useEffect(() => {
        const ActualizarForm = () => {
            cambiarErrores([]);
            if (!ValidarObjetoVacio(data)) {
                form.setFieldsValue(acomodarDatosMostrar ? acomodarDatosMostrar(data) : data)
            } else if (!cargando) {
                form.resetFields()
                cambiarEstatus('nuevo')
            }
        }
        return ActualizarForm();

    }, [data])
}

export default useActualizarForm;
