import React from 'react';
import styled from 'styled-components';
import NoDisponible from 'components/vistas/elements/NoDisponible';

const ListaSimple = ({ lista }) => {
    return (
        <>
            {lista?.length === 0 ? <NoDisponible /> :
                <ListaEstilada>
                    {
                        lista?.map((renglon, index) => {
                            return (
                              <li key={index}>{renglon.nombre}: {renglon.valor}</li>  
                            )
                        })
                    }
                </ListaEstilada>
            }
        </>
    )
}
export default ListaSimple

const ListaEstilada = styled.ul`
    list-style: none;
    padding-left: 0px;
`