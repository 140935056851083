import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Menu from 'configs/layout/components/Menu';

const DrawerMenuMovil = () => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button type="ghost" onClick={() => setVisible(true)} icon={<MenuOutlined />} />
            <Drawer title="Basic Drawer" placement="right" onClose={() => setVisible(false)} visible={visible}>
                <Menu />
            </Drawer>
        </>
    );
}

export default DrawerMenuMovil;