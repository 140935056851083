
const GenerarObjetoDeFiltrosUrl = (searchParams) => {
    let filtros = {}
    Array.from(searchParams.keys()).forEach(key => {
        filtros = { ...filtros, [key]: searchParams.get(key) }
    });
    return filtros
}

const ModificarFiltrosUrl = (data, setSearchParams) => {
    let obj = data
    Object.keys(obj).forEach(key => {
        if (obj[key] === '' || obj[key] === undefined) {
            delete obj[key];
        }
    });
    setSearchParams(obj)
}

const EliminarFiltroUrl = (searchParams, setSearchParams, param) => {
    let nuevosFiltros = GenerarObjetoDeFiltrosUrl(searchParams);
    delete nuevosFiltros[param]
    delete nuevosFiltros['page']
    ModificarFiltrosUrl(nuevosFiltros, setSearchParams)
}

const EliminarTodosLosFiltros = (searchParams, setSearchParams) => {
    let objFiltros = {}
    Array.from(searchParams.keys()).forEach(element => {
        objFiltros = { ...objFiltros, [element]: '' }
    });
    setSearchParams({});
}

const CambiarPaginacion = (pagination, searchParams, setSearchParams) => {
    const { current: page, pageSize } = pagination
    let filtros = GenerarObjetoDeFiltrosUrl(searchParams)
    if (page !== 1) { filtros = {...filtros, page} } else { delete filtros['page'] }
    if (pageSize !== 25) { filtros = {...filtros, pageSize} } else { delete filtros['pageSize'] }
    setSearchParams(filtros)
}

const ValidarObjetoVacio = (obj) => {
    //* Sin keys en el objeto === {}
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
}

export { ModificarFiltrosUrl, GenerarObjetoDeFiltrosUrl, EliminarFiltroUrl, EliminarTodosLosFiltros, ValidarObjetoVacio, CambiarPaginacion }