import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Lo siento, parece que la página que buscas ya no existe o cambio de ubicación."
            extra={
                <Button type="primary" onClick={() => navigate('/')}>Volver al Inicio</Button>
            }
        />
    );
}

export default Error404;