import { useEffect, useRef } from 'react';
import { ValidarUsuario } from 'functions/FuncUsuario';
import { useAuth } from 'contexts/AuthContext';

const useValidarUsuario = () => {
    const { usuario, cambiarUsuario } = useAuth();
    const validarAlUsuario = useRef(true)

    useEffect(() => {
        const ValidacionUsuario = () => {
            if (usuario) {
                if (validarAlUsuario.current) {
                    ValidarUsuario({ cambiarUsuario, usuario, validarAlUsuario })
                }
            }
        }
        return ValidacionUsuario();
    }, [usuario])
}

export default useValidarUsuario;