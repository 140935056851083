import React from 'react';
import { Layout as LayoutAntd, Typography } from 'antd';


const Footer = () => {
    return (
        <LayoutAntd.Footer style={{ textAlign: 'center' }}>
            <Typography.Text type="secondary">
                Sitio desarrollado por <Typography.Link href='https://caloja.com.mx/' target="_blank">Asesoria Caloja S.A de C.V.</Typography.Link>
            </Typography.Text>
        </LayoutAntd.Footer>
    );
}

export default Footer;