import React from 'react';
import { PageHeader, Button, Dropdown, Menu } from 'antd';
import TagsFiltrosActivos from 'core/tables/elements/TagsFiltrosActivos';
import { PlusOutlined, EditOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const HeaderTabla = ({ tablaNombre, form, tipo, elementoSeleccionado, loading }) => {
    let navigate = useNavigate();
    let location = useLocation();

    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<EyeOutlined />} onClick={() => navigate(`/sistema/catalogo/${tipo}/busqueda?id=${elementoSeleccionado}`)} >
                Ver
            </Menu.Item>
            <Menu.Item key="2" icon={<CopyOutlined />} onClick={() => navigate(`/sistema/catalogo/${tipo}/captura?id=${elementoSeleccionado}`, {state: {estatus: 'duplicado', origen: location.pathname + location.search}})}>
                Duplicar
            </Menu.Item>
        </Menu>
    );

    return (
        <PageHeader
            title={tablaNombre}
            style={{ padding: '0px 0px 24px' }}
            tags={<TagsFiltrosActivos form={form} />}
            extra={[
                <Button
                    key={1}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => navigate(`/sistema/catalogo/${tipo}/captura`)}
                    disabled={loading}
                    style={{marginRight: 12}}
                >
                    Nuevo
                </Button>,
                <Dropdown.Button
                    key={2}
                    type="default"
                    overlay={menu}
                    onClick={() => navigate(`/sistema/catalogo/${tipo}/captura?id=${elementoSeleccionado}`, {state: {estatus: 'editando'}})}
                    disabled={elementoSeleccionado ? false : true}
                >
                    <EditOutlined /> Editar
                </Dropdown.Button>
            ]}
        />
    );
}

export default HeaderTabla;