import { FormatoFecha } from 'functions/Formatos';
import { Tag } from 'antd';

const tablaClientes = [
    {
        title: 'Clientes',
        dataIndex: 'nombre',
        width: 'auto',
    },
    {
        title: 'Correo',
        dataIndex: 'correo',
        width: '40%',
        responsive: ['md'],
    },
]

const tablaModelos = [
    {
        title: 'No. Modelo (ID Patterns)',
        dataIndex: 'identificador',
        width: '15%',
    },
    {
        title: 'Nombre del Modelo (Patterns Name)',
        dataIndex: 'nombre',
        width: '30%',
    },
    {
        title: 'Cliente (Client)',
        dataIndex: 'cliente',
        width: '30%',
        render: cliente => {
            const { nombre } = cliente?.data?.attributes ?? {}
            return (
                <>
                    {nombre ?? 'ERROR: Valida con el equipo de desarrollo.'}
                </>
            )
        }
    },
    {
        title: 'Ubicación (Location)',
        dataIndex: 'ubicacion',
        sorter: false,
        width: '25%',
    },
]

const tablaPedimentos = [
    {
        title: 'Fecha (Date)',
        dataIndex: 'fecha',
        width: '15%',
        render: fecha => {
            return FormatoFecha(fecha)
        }
    },
    {
        title: 'No. Pedimento (ID Doc. Exp)',
        dataIndex: 'numeroPedimento',
        width: '20%',
    },
    {
        title: 'Clave (Key)',
        dataIndex: 'clavePedimento',
        sorter: false,
        width: '15%',
        render: clave =>
            <Tag color={clave === 'A1' ? 'yellow' : 'green'}>
                {clave}
            </Tag>
    },
    {
        title: 'Modelo (Patterns)',
        dataIndex: 'modelo',
        width: '35%',
        render: modelo => {
            const { nombre } = modelo?.data?.attributes
            return (
                <>
                    {nombre ?? 'ERROR: Valida con el equipo de desarrollo.'}
                </>
            )
        }
    },
    {
        title: 'Factura (Invoice)',
        dataIndex: 'factura',
        width: '15%',
    },
]

const tablaMantenimientos = [
    {
        title: 'Fecha (Date)',
        dataIndex: 'fecha',
        width: '15%',
        render: fecha => {
            return FormatoFecha(fecha)
        }
    },
    {
        title: 'Responsable',
        dataIndex: 'responsable',
        width: '25%',
    },
    {
        title: 'Modelo (Pattern)',
        dataIndex: 'modelo',
        width: '40%',
        render: modelo => {
            const { nombre } = modelo?.data?.attributes
            return (
                <>
                    {nombre ?? 'ERROR: Valida con el equipo de desarrollo.'}
                </>
            )
        }
    },
    {
        title: 'Lugar',
        dataIndex: 'lugar',
        width: '20%',
    },
]

export { tablaClientes, tablaModelos, tablaPedimentos, tablaMantenimientos }
