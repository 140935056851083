import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from 'contexts/AuthContext';
import RutaPrivada from 'configs/tools/RutaPrivada';
import Layout from 'configs/layout/Layout';
import Login from 'components/login/Login';
import Inicio from 'components/inicio/Inicio';
import TablaCatalogo from 'core/tables/TablaCatalogo';
import 'App.css';
import Error404 from 'configs/errors/Error404';
import Formulario from 'core/form/Formulario';
import VerRegistros from 'core/view/VerRegistro';
import TabConfig from 'components/cuenta/TabConfig';

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/sistema" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sistema" element={<RutaPrivada><Layout /></RutaPrivada>}>
          <Route index element={<Inicio />} />
          <Route path="catalogo/:tipo">
            <Route path="lista" element={<TablaCatalogo />} />
            <Route path="captura" element={<Formulario />} />
            <Route path="busqueda" element={<VerRegistros />} />
          </Route>
          <Route path="config/cuenta" element={<TabConfig />} />
        </Route>
        <Route path="*" element={<RutaPrivada><Error404 /></RutaPrivada>} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
