import { useState, useEffect } from 'react';
import { GenerarObjetoDeFiltrosUrl, ValidarObjetoVacio } from 'functions/FuncFiltros';
import { useSearchParams } from 'react-router-dom';

const useObtenerFiltrosActivos = () => {
    const [filtrosActivos, cambiarFiltrosActivos] = useState([])
    let [searchParams] = useSearchParams();

    useEffect(() => {
        const validarFiltros = () => {
            const filtros = GenerarObjetoDeFiltrosUrl(searchParams)
            if (!ValidarObjetoVacio(filtros)) {
                const ArrayFiltros = Object.entries(filtros)
                const ArrayFiltrosPurge = []
                ArrayFiltros.forEach((filtro) => {
                    if (filtro[1] && !ListaFiltrosIgnorados.find(element => element === filtro[0])) {
                        ArrayFiltrosPurge.push({ name: filtro[0], value: filtro[1] })
                    }
                })
                if (ArrayFiltrosPurge.length !== 0) {
                    cambiarFiltrosActivos(ArrayFiltrosPurge)
                } else {
                    cambiarFiltrosActivos([])
                }
            } else {
                cambiarFiltrosActivos([])
            }
        }
        return validarFiltros();
    }, [searchParams])

    return { filtrosActivos }
}

export default useObtenerFiltrosActivos;

const ListaFiltrosIgnorados = ['page', 'pageSize']