import React from 'react';
import { List } from 'antd';
import { FormatoFecha } from 'functions/Formatos';

const ListaDocumentos = ({ lista }) => {

    return (
        <List
            bordered={false}
            dataSource={lista}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<a href={item.url} target="_blank">{item.name}</a>}
                        description={`Subido el ${FormatoFecha(item.createdAt)}`}
                    />
                </List.Item>
            )} />
    );
}

export default ListaDocumentos;