import React, { useState } from 'react';
import { Card, Form, Row } from 'antd';
import useValidarTipoUrl from 'core/custom/useValidarTipoUrl';
import { CapturasConfig } from 'configs/custom/Capturas';
import { useNavigate } from 'react-router-dom';

import HeaderFormulario from 'core/form/elements/HeaderFormulario';
import Spinner from 'core/other/Spinner';
import BotonesFormulario from 'core/form/elements/BotonesFormulario';

import useActualizarForm from 'core/form/elements/hooks/useActualizarForm';
import useObtenerEstatus from 'core/form/elements/hooks/useObtenerEstatus';
import useVaciarCamposAlDuplicar from 'core/form/elements/hooks/useVaciarCamposAlDuplicar';
import useObtenerRegistro from 'hooks/useObtenerRegistro';
import { ModificaronFormulario, GuardarDatos } from 'functions/FuncFormularios';

const Formulario = () => {
    const [form] = Form.useForm();
    const [cargando, cambiarCargando] = useState(true)
    const [errores, cambiarErrores] = useState([])
    let navigate = useNavigate();

    const { tipo } = useValidarTipoUrl({ ElementConfig: CapturasConfig });
    const { capturaNombre, FormularioComponente, api, acomodarDatosMostrar, camposVaciarAlDuplicar } = CapturasConfig[tipo] ?? {}

    const { estatus, cambiarEstatus } = useObtenerEstatus()
    const { data, token, id, modificarFiltrosUrl, eliminarTodosLosFiltrosUrl, cambiarData } = useObtenerRegistro({ api, cambiarCargando });
    
    useActualizarForm({ form, data, cargando, cambiarEstatus, acomodarDatosMostrar, cambiarErrores })
    useVaciarCamposAlDuplicar({ estatus, camposVaciarAlDuplicar, form, cargando })

    const handleOnFinish = async (data, accion) => {
        if (ValidarEstatus[estatus]) { return }
        const Id = estatus === 'editando' ? id : ''
        const method = Methods[estatus]

        const result = await GuardarDatos({ data, api, Id, method, token, cambiarCargando, cambiarErrores })

        if (result) {
            if (accion === 'nuevo') {
                eliminarTodosLosFiltrosUrl();
                cambiarData({})
            } else if (accion === 'salir') {
                navigate(-1)
            } else {
                modificarFiltrosUrl({ id: result })
                cambiarEstatus('editando')
                cambiarErrores([])
            }
        }
    }

    const handleOnFinishFailed = ({ errorFields }) => {
        const Errores = []
        errorFields.forEach(element => {
            Errores.push(element.name[0])
        });
        cambiarErrores(Errores);
    }

    return (
        <Card>
            <Spinner cargando={cargando}>
                <HeaderFormulario
                    title={capturaNombre}
                    estatus={estatus}
                    cambiarEstatus={cambiarEstatus}
                    cargando={cargando}
                    ModificaronFormulario={() => ModificaronFormulario({ estatus, data, form })}
                />
                <Form layout="vertical" form={form} hideRequiredMark onFinishFailed={handleOnFinishFailed} onFinish={handleOnFinish} style={{ padding: '16px 24px' }}>
                    <Row gutter={24}>
                        {
                            FormularioComponente && <FormularioComponente errores={errores} data={data} />
                        }
                    </Row>
                    <BotonesFormulario
                        ModificaronFormulario={() => ModificaronFormulario({ estatus, data, form })}
                        form={form}
                        handleOnFinish={handleOnFinish}
                        cambiarErrores={cambiarErrores}
                    />
                </Form>
            </Spinner>
        </Card>
    );
}

export default Formulario;

const ValidarEstatus = {
    error: true,
    cargando: true,

}

const Methods = {
    nuevo: 'post',
    duplicado: 'post',
    editando: 'put',
}



