import React from 'react';
import { InputTexto } from 'core/other/Inputs';

const FormularioCliente = ({ data, errores }) => {
    const config = {
        nombre: {
            name: 'nombre',
            label: 'Nombre (Name)',
            tooltip: 'Ingresa el nombre o razón social del cliente.',
            size: 'small',
            validateStatus: errores?.includes('nombre') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        correo: {
            name: 'correo',
            label: 'Correo (Email)',
            tooltip: 'Ingresa el correo electronico del cliente.',
            size: 'small',
            validateStatus: errores?.includes('correo') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' },
                { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'El correo no es valido.' },
            ],
        }
    }

    return (
        <>
            <InputTexto {...config['nombre']} />
            <InputTexto {...config['correo']} />
        </>
    );
}

export default FormularioCliente;