import React from 'react';
import { Row, Col, Card, Space, Button, Typography } from 'antd';
import styled from 'styled-components';
import { LoginOutlined } from '@ant-design/icons';
import Spinner from 'core/other/Spinner';

const ContenedorFormularioLogin = ({ children, ...props }) => {
    const { cargando } = props

    return (
        <ContenedorEstilado justify='center' align='middle'>
            <Col {...ResponsiveCol} align='middle'>
                <Spinner cargando={cargando} transparent={true}>
                    <CardGlassEffect>
                        <SpaceStyled direction="vertical" size="middle">
                            <Typography.Title>FINSA</Typography.Title>
                            <Typography.Title level={4}>Sistema Administrativo</Typography.Title>
                            {children}
                        </SpaceStyled>
                    </CardGlassEffect>
                </Spinner>
            </Col>
        </ContenedorEstilado>
    );
}

const BotonIngresar = ({ cargando }) => {
    return (
        <Button size='large' block loading={cargando} htmlType='submit' icon={<LoginOutlined />} type='ghost'>Ingresar</Button>
    )
}

export { ContenedorFormularioLogin, BotonIngresar };

const ResponsiveCol = {
    xs: 20,
    sm: 16,
    md: 14,
    lg: 12,
    xl: 10,
    xxl: 8
}

const CardGlassEffect = styled(Card)`
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 500px;
    max-width: 80vw;
`

const ContenedorEstilado = styled(Row)`
    width: 100vw;
    height: 100vh;
    background-image: url('https://res.cloudinary.com/rlome/image/upload/v1649350118/fondo_yx6z1e.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const SpaceStyled = styled(Space)`
    width: 400px;
    max-width: 65vw;
    padding: 20px 0px;
`