import React, { Fragment } from 'react';
import { Menu as MenuAntd } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Rutas from 'configs/custom/Rutas';

const Menu = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <MenuAntd style={{ backgroundColor: 'transparent' }} mode="inline" selectedKeys={pathname} onClick={({ key }) => navigate(key)} >
            {
                Rutas.map((renglon, index) => {
                    return (
                        <Fragment key={index}>
                            {
                                renglon.submenu ?
                                    <MenuAntRutaSubMenu key={renglon.ruta} icon={renglon.icono} title={renglon.nombre} rutasSubmenu={renglon.submenuRutas} />
                                    :
                                    <MenuAntRutaItem title={renglon.nombre} key={renglon.ruta} icon={renglon.icono} />
                            }
                        </Fragment>
                    )
                })
            }
        </MenuAntd>
    );
}

export default Menu;

const MenuAntRutaSubMenu = ({ rutasSubmenu, ...props }) => {
    return (
        <MenuAntd.SubMenu {...props}>
            {rutasSubmenu.map((subRenglon) => {
                return (
                    <MenuAntRutaItem title={subRenglon.nombre} key={subRenglon.ruta} icon={subRenglon.icono} />
                )
            })}
        </MenuAntd.SubMenu>
    )
}

const MenuAntRutaItem = ({ title, ...props }) => {
    return (
        <MenuAntd.Item {...props}>
            {title}
        </MenuAntd.Item>
    )
}