import React from 'react';
import { Image, Empty, Space, Row } from 'antd';

const ListaFotos = ({ lista }) => {

    return (
        <Image.PreviewGroup>
            {
                lista?.length === 0 ?
                    <Row justify="center" style={{ width: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Row>
                    :
                    <Space size="middle">
                        {
                            lista?.map(({ id, thumbnail, url }) => {
                                return <Image key={id} width={150} preview={thumbnail} src={url} />
                            })
                        }
                    </Space>

            }
        </Image.PreviewGroup>
    );
}

export default ListaFotos;