import { tablaClientes, tablaModelos, tablaPedimentos, tablaMantenimientos } from 'configs/custom/Tablas';
import { QueryTablaClientes, QueryTablaModelos, QueryTablaPedimentos, QueryTablaMantenimientos } from 'configs/custom/Querys';
import { clientesFiltros, modelosFiltros, pedimentosFiltros, mantenimientosFiltros } from 'configs/custom/Filtros';

const CatalogosConfig = {
    clientes: {
        tablaNombre: 'Tabla de Clientes',
        tablaColumnas: tablaClientes,
        tablaFiltros: clientesFiltros,
        tablaConfigHook: {
            endpoint: 'clientes',
            generateQuery: QueryTablaClientes,
        },

    },
    modelos: {
        tablaNombre: 'Tabla de Modelos',
        tablaColumnas: tablaModelos,
        tablaFiltros: modelosFiltros,
        tablaConfigHook: {
            endpoint: 'modelos',
            generateQuery: QueryTablaModelos,
        },

    },
    pedimentos: {
        tablaNombre: 'Tabla de Pedimentos',
        tablaColumnas: tablaPedimentos,
        tablaFiltros: pedimentosFiltros,
        tablaConfigHook: {
            endpoint: 'pedimentos',
            generateQuery: QueryTablaPedimentos,
        },
    },
    mantenimientos: {
        tablaNombre: 'Tabla de Mantenimientos',
        tablaColumnas: tablaMantenimientos,
        tablaFiltros: mantenimientosFiltros,
        tablaConfigHook: {
            endpoint: 'mantenimientos',
            generateQuery: QueryTablaMantenimientos,
        },

    },
}

export { CatalogosConfig }