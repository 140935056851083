import React from 'react';
import { Form, Upload, Col, Button, Typography, Tooltip } from 'antd';
import { UploadOutlined, PlusOutlined, WarningFilled, LinkOutlined } from '@ant-design/icons';
import Sizes from 'configs/custom/Sizes';
import { useAuth } from 'contexts/AuthContext';
import { FuncionMostrarAlertaEstatus, BuscarArchivoEliminar, GuardarArchivos, ActualizarValoresDelForm } from 'functions/FuncArchivos';
import styled from 'styled-components';

const SubirArchivo = ({ accept, name, listType, size, label }) => {
    const { token } = useAuth();

    const props = {
        name: name,
        multiple: true,
        accept: accept,
        listType: listType,
        headers: { 'Authorization': `Bearer ${token}`, "content-type": 'multipart/form-data;' },
        customRequest: (event) => GuardarArchivos(event),
        onChange: ({ file }) => { FuncionMostrarAlertaEstatus({ file }) },
        onRemove: ({ id }) => { BuscarArchivoEliminar({ id, token }) },
    };

    return (
        <Col {...Sizes[size]}>
            {listType === 'text' ? LinkReducirDocumentos : LinkReducirImagenes}
            <Form.Item name={name} label={label}
                valuePropName="fileList"
                getValueFromEvent={ActualizarValoresDelForm}
                
            >
                <Upload {...props}>
                    {listType === 'text' ? btnSubirDocumentos : btnSubirImagen}
                </Upload>
            </Form.Item>
        </Col>
    );
}

export default SubirArchivo;

const ContenedorEstilado = styled.div`
    margin-bottom: 8px;
`

const btnSubirImagen = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Agregar</div>
    </div>
);

const btnSubirDocumentos = (
    <ContenedorEstilado>
        <Button icon={<UploadOutlined />} htmlType="button" >Subir Documento</Button>
    </ContenedorEstilado>
)

const LinkReducirImagenes = (
    <ContenedorEstilado>
        <Typography.Text type="warning"><WarningFilled /> Recuerda: </Typography.Text>
        <Typography.Text>Reducir el peso de las imagenes </Typography.Text>
        <Tooltip title="Herramienta online para reducir en el peso de las imagenes, si tienes dudas contacta a soporte">
            <Typography.Link href="https://tinypng.com/" target="_blank"><LinkOutlined /> Link</Typography.Link >
        </Tooltip>
    </ContenedorEstilado>
)

const LinkReducirDocumentos = (
    <ContenedorEstilado>
        <Typography.Text type="warning"><WarningFilled /> Recuerda: </Typography.Text>
        <Typography.Text>Comprimir tus documentos </Typography.Text>
        <Tooltip title="Herramienta online para comprimir tus documentos, si tienes dudas contacta a soporte">
            <Typography.Link href="https://www.ilovepdf.com/es/comprimir_pdf" target="_blank"><LinkOutlined /> Link</Typography.Link >
        </Tooltip>
    </ContenedorEstilado>
)

