import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import SeleccionarElemento from 'core/view/elements/SeleccionarElemento';
import useFiltrosUrl from 'core/custom/useFiltrosUrl';

const HeaderView = ({ title, vistaConfigSelect }) => {
    const { id } = useFiltrosUrl();
    const [isModalVisible, setIsModalVisible] = useState(id ? false : true);
    let navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setIsModalVisible(false)
        }
    }, [id])

    return (
        <>
            <PageHeader
                title={title}
                onBack={() => navigate(-1)}
                extra={<Button onClick={() => setIsModalVisible(true)}>Seleccionar Cliente</Button>}
            />
            <Modal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                title={`Seleccionar ${title}`}
                footer={null}
                destroyOnClose={true}
            >
                <SeleccionarElemento vistaConfigSelect={vistaConfigSelect} />
            </Modal>
        </>
    );
}

export default HeaderView;