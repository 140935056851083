import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Side from 'configs/layout/components/Side';
import Header from 'configs/layout/components/Header';
import Footer from 'configs/layout/components/Footer';
import { Layout as LayoutAntd, Grid } from 'antd';
import styled from 'styled-components';
import useValidarUsuario from 'configs/layout/components/useValidarUsuario';
import 'configs/layout/components/Responsive.css'
const { useBreakpoint } = Grid

const Layout = () => {
    const [collapsed, changeCollapsed] = useState(false)
    const screens = useBreakpoint();
    const { md } = screens

    useValidarUsuario();

    return (
        <LayoutStyled>
            <Header />
            <LayoutAntd hasSider={md}>
                {md && <Side collapsed={collapsed} changeCollapsed={changeCollapsed} />}
                <ContentAntd side={md?.toString()} collapsed={collapsed?.toString()}>
                    <Outlet />
                    <Footer />
                </ContentAntd>
            </LayoutAntd>
        </LayoutStyled>
    );
}

export default Layout;

const LayoutStyled = styled(LayoutAntd)`
    height: 100vh;
    /* min-height: 100vh; */
    max-height: 100vh;
`

const FuncionTieneMargen = (props) => {
    const { side, collapsed } = props
    if (side === 'false') { return '0px' }
    if (collapsed === 'false') {
        return '250px'
    } else {
        return '80px'
    }
}

const FuncionPadding = (props) => {
    const { side } = props
    if (side === 'false') {
        return '16px 12px'
    }
    return '32px 24px'
}

const ContentAntd = styled(LayoutAntd.Content)`
    margin-top: 64px;
    padding: ${props => FuncionPadding(props)};
    transition: all 0.2s ease-out;
    margin-left: ${props => FuncionTieneMargen(props)};
`