import React, { useState } from 'react';
import { Row, Col, Card, Statistic } from 'antd';
import { UserOutlined, ArrowDownOutlined, CalendarOutlined } from '@ant-design/icons';

const Inicio = () => {
    const [cargando, cambiarCargando] = useState(true)

    return (
        <Row gutter={16}>
            <Col span={4}>
                <Card>
                    <Statistic
                        title="Empleados Activos"
                        value={22}
                        prefix={<UserOutlined />}
                        loading={cargando}
                    />
                </Card>
            </Col>
            {/* <Col span={4}>
                <Card>
                    <Statistic
                        title="Tabla de ISR"
                        value={2022}
                        groupSeparator=""
                        prefix={<CalendarOutlined />}
                        loading={cargando}
                    />
                </Card>
            </Col> */}
            {/* <Col span={12}>
                <Card onClick={() => cambiarCargando(false)}>
                    <Statistic
                        title="Dolar"
                        value={9.3}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col> */}
        </Row>
    );
}

export default Inicio;