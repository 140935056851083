import FetcherSwr from 'configs/tools/FetcherSwr';
import { FuncionAcomodarData } from 'functions/FuncHooks';

const useObtenerListaTabla = ({ tablaConfigHook }) => {
    const { endpoint, generateQuery } = tablaConfigHook ?? {}
    
    const newQuery = generateQuery ? generateQuery() : {}
    
    const { data, isLoading, isError } = FetcherSwr({ query: newQuery, endpoint });

    return { 
        data: FuncionAcomodarData(data?.data),
        meta: {...data?.meta?.pagination, current: data?.meta?.pagination.page} ?? {},
        isLoading,
        isError
    }
}

export default useObtenerListaTabla;