import React from 'react';
import { Divider } from 'antd';
import { InputTexto, InputTextArea, InputList } from 'core/other/Inputs';
import { SelectDataRemote } from 'core/other/Selects';
import SubirArchivo from 'core/other/SubirArchivo';
import { ValidarObjetoVacio } from 'functions/FuncFiltros';

const FormularioModelo = ({ data, errores }) => {

    const config = {
        identificador: {
            name: 'identificador',
            label: 'No. Modelo (ID Patterns)',
            tooltip: 'Ingresa un Identificador único',
            size: 'middle',
            validateStatus: errores?.includes('identificador') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        nombre: {
            name: 'nombre',
            label: 'Nombre del Modelo (Patterns Name)',
            tooltip: 'Ingresa el nombre del Modelo',
            size: 'middle',
            validateStatus: errores?.includes('nombre') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        cliente: {
            name: 'cliente',
            label: 'Cliente (Customer)',
            tooltip: 'Selecciona un Cliente',
            size: 'middle',
            endpoint: 'clientes',
            camposBusqueda: ['nombre'],
            vistaResultados: (renglon) => `${renglon.nombre}`,
            optSeleccionado: !ValidarObjetoVacio(data) && { ...data?.cliente?.data?.attributes, id: data?.cliente?.data?.id },
            validateStatus: errores?.includes('cliente') ? 'error' : false,
            rules: [
                { required: true, message: 'Campo requerido.' },
            ],
        },
        descripcion: {
            name: 'descripcion',
            label: 'Descripción (Description)',
            tooltip: 'Ingresa una descripción del Modelo',
            size: 'large',
            validateStatus: errores?.includes('descripcion') ? 'error' : false,
            rules: [
                { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        condiciones: {
            name: 'condiciones',
            label: 'Condiciones (Terms)',
            tooltip: 'Ingresa las condiciones importantes del Modelo',
            size: 'large',
            validateStatus: errores?.includes('condiciones') ? 'error' : false,
            rules: [
                // { required: true, message: 'Campo requerido.' },
                { min: 5, message: 'El campo no puede ser menor de 5 Caracteres.' }
            ],
        },
        medidas: {
            name: 'medidas',
            label: 'Medidas (Sizes)',
            tooltip: 'Ingresa las medidas del Modelo',
            size: 'middle',
            textoAgregar: 'Agregar medida',
        },
        caracteristicas: {
            name: 'caracteristicas',
            label: 'Características (Features)',
            tooltip: 'Ingresa las características del Modelo',
            size: 'middle',
            textoAgregar: 'Agregar Característica',
        },
        ubicacion: {
            name: 'ubicacion',
            label: 'Ubicación (Location)',
            tooltip: 'Ingresa la ubicación del Modelo',
            size: 'middle',
            validateStatus: errores?.includes('ubicacion') ? 'error' : false,
            configElement: {
                allowClear: true,
            },
            rules: [
                { required: true, message: 'Campo requerido.' },
            ],
        },
        fotos: {
            name: 'fotos',
            label: 'Fotos',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            size: 'large',

        },
        documentos: {
            name: 'documentos',
            label: 'Documentos',
            listType: 'text',
            accept: 'application/pdf',
            size: 'middle',
        },
    }

    return (
        <>
            <InputTexto {...config['identificador']} />
            <InputTexto {...config['nombre']} />
            {/* ! FALTA EL SELECT DE CLIENTE IMPORTANTE */}
            <SelectDataRemote {...config['cliente']} />
            <InputTextArea {...config['descripcion']} />
            <InputTextArea {...config['condiciones']} />

            <Divider />
            <InputList {...config['medidas']} />
            <InputList {...config['caracteristicas']} />
            <InputTexto {...config['ubicacion']} />

            <SubirArchivo {...config['fotos']} />
            <SubirArchivo {...config['documentos']} />
        </>
    );
}

export default FormularioModelo;