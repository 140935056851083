import React from 'react';
import { Descriptions, Space, Tabs, Empty } from 'antd';
import styled from 'styled-components';
import ListaSimple from 'components/vistas/elements/ListaSimple';
import NoDisponible from 'components/vistas/elements/NoDisponible';
import ListaFotos from 'components/vistas/elements/ListaFotos';
import ListaDocumentos from 'components/vistas/elements/ListaDocumentos';
import { FuncionAcomodarData } from 'functions/FuncHooks';
import { ToolOutlined, FileOutlined } from '@ant-design/icons';

const VistaModelos = ({ data }) => {
    const { identificador, nombre, cliente, ubicacion, descripcion, condiciones, medidas, caracteristicas, fotos, documentos, pedimentos } = data
    // console.log(pedimentos);

    return (
        <ContenedorSpace direction="vertical" size="large">
            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} bordered>
                <Descriptions.Item label="No. Modelo">{identificador ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Nombre del Modelo">{nombre ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Cliente">{cliente?.data?.attributes?.nombre ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Ubicación">{ubicacion ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Descripción">{descripcion ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Condiciones">{condiciones ?? <NoDisponible />}</Descriptions.Item>
                <Descriptions.Item label="Caracteristicas"><ListaSimple lista={caracteristicas} /></Descriptions.Item>
                <Descriptions.Item label="Medidas"><ListaSimple lista={medidas} /></Descriptions.Item>
                <Descriptions.Item label="Fotos"><ListaFotos lista={FuncionAcomodarData(fotos?.data)} /></Descriptions.Item>
                <Descriptions.Item label="Documentos"><ListaDocumentos lista={FuncionAcomodarData(documentos?.data)} /></Descriptions.Item>
            </Descriptions>
            <Tabs defaultActiveKey="1" >
                <Tabs.TabPane tab={<GenerarNombreTab Icono={FileOutlined} nombre="Pedimentos (PENDIENTE)" />} key="1">
                    {
                        pedimentos?.data?.length === 0 ?
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                        <>Hay Algo</>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane tab={<GenerarNombreTab Icono={ToolOutlined} nombre="Mantenimientos (PENDIENTE)" />} key="2">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Tabs.TabPane>
            </Tabs>
        </ContenedorSpace>
    );
}

export default VistaModelos;

const GenerarNombreTab = ({ nombre, Icono }) => {
    return (
        <span>
            <Icono />
            {nombre}
        </span>
    )
}

const ContenedorSpace = styled(Space)`
    padding: 16px 24px;
    width: 100%;
`



