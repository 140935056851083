import React, { useContext, useState, useEffect } from 'react';

//creamos el contexto
const AuthContext = React.createContext();

//Hook para acceder al contexto
const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
    const usuarioGuardado = sessionStorage.getItem('usuario') ? JSON.parse(sessionStorage.getItem('usuario')) : false;

    const [usuario, cambiarUsuario] = useState(usuarioGuardado);
    const [cargando, cambiarCargando] = useState(true);

    useEffect(() => {
        sessionStorage.setItem('usuario', JSON.stringify(usuario));
    }, [usuario]);

    return (
        <AuthContext.Provider value={{ usuario, cambiarUsuario, cargando, cambiarCargando, token: usuario.token }} >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext, useAuth };