import { Layout, Row, Col, Typography, Grid } from 'antd';
import BotonMenu from 'configs/layout/components/header/BotonMenu';
import DrawerMenuMovil from 'configs/layout/components/header/DrawerMenuMovil';
const { useBreakpoint } = Grid

const Header = () => {
    const screens = useBreakpoint();
    const { md } = screens

    return (
        <Layout.Header
            style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
            <Row justify="space-between" align="middle">
                <Col>
                    <Typography.Title level={2} style={{ marginBottom: 0 }}>FINSA</Typography.Title>
                </Col>
                <Col>
                    {md ?
                        <BotonMenu /> : <DrawerMenuMovil />
                    }
                </Col>
            </Row>
        </Layout.Header>
    );
}

export default Header;




