import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useElementoSeleccionado = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const [elementoSeleccionado, cambiarElementoSeleccionado] = useState(location?.state?.elementoSeleccionado ?? false)

    useEffect(() => {
        const ActualizarState = () => {
            navigate(location.pathname + location.search, { replace: true, state: { elementoSeleccionado } })
        }
        return ActualizarState();
    }, [elementoSeleccionado])

    return { elementoSeleccionado, cambiarElementoSeleccionado }
}

export default useElementoSeleccionado;