import { useSearchParams } from 'react-router-dom';
import { GenerarObjetoDeFiltrosUrl, ValidarObjetoVacio, ModificarFiltrosUrl, EliminarFiltroUrl, EliminarTodosLosFiltros } from 'functions/FuncFiltros';

const useFiltrosUrl = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    return {
        filtros: GenerarObjetoDeFiltrosUrl(searchParams),
        id: GenerarObjetoDeFiltrosUrl(searchParams).id ??= false,
        haveFiltros: !ValidarObjetoVacio(GenerarObjetoDeFiltrosUrl(searchParams)),
        modificarFiltrosUrl: (data) => ModificarFiltrosUrl(data, setSearchParams),
        eliminarFiltroUrl: (param) => EliminarFiltroUrl(searchParams, setSearchParams, param),
        eliminarTodosLosFiltrosUrl: () => EliminarTodosLosFiltros(searchParams, setSearchParams),
    }

}

export default useFiltrosUrl;