import { useEffect } from 'react';

const useVaciarCamposAlDuplicar = ({ estatus, camposVaciarAlDuplicar, form, cargando }) => {
    const { resetFields } = form

    useEffect(() => {
        if (estatus === 'duplicado' && !cargando) {
            resetFields(camposVaciarAlDuplicar)
        }
    }, [estatus, camposVaciarAlDuplicar, cargando])
}

export default useVaciarCamposAlDuplicar;