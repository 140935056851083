import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const useValidarTipoUrl = ({ ElementConfig }) => {
    let urlParams = useParams();
    let navigate = useNavigate();
    const { tipo } = urlParams

    useEffect(() => {
        if (!ElementConfig[tipo]) {
            return navigate('/error-404')
        }
    }, [urlParams])

    return { tipo }
}

export default useValidarTipoUrl