import { UserOutlined, HomeFilled, FileOutlined, GatewayOutlined, ToolOutlined, PlusOutlined, UnorderedListOutlined, EyeOutlined } from '@ant-design/icons';

const Rutas = [
    {
        nombre: 'Inicio (Home)',
        ruta: '/sistema',
        icono: <HomeFilled />,
        submenu: false,
    },
    {
        nombre: 'Clientes (Customers)',
        ruta: '/sistema/catalogo/clientes',
        icono: <UserOutlined />,
        submenu: true,
        submenuRutas: [
            {
                nombre: 'Ver Cliente',
                ruta: '/sistema/catalogo/clientes/busqueda',
                icono: <EyeOutlined />,
            },
            {
                nombre: 'Nuevo Cliente',
                ruta: '/sistema/catalogo/clientes/captura',
                icono: <PlusOutlined />,
            },
            {
                nombre: 'Lista de Clientes',
                ruta: '/sistema/catalogo/clientes/lista',
                icono: <UnorderedListOutlined />,
            },
        ]
    },
    {
        nombre: 'Modelos (Patterns)',
        ruta: '/sistema/catalogo/modelos',
        icono: <GatewayOutlined />,
        submenu: true,
        submenuRutas: [
            {
                nombre: 'Ver Modelo',
                ruta: '/sistema/catalogo/modelos/busqueda',
                icono: <EyeOutlined />,
            },
            {
                nombre: 'Nuevo Modelo',
                ruta: '/sistema/catalogo/modelos/captura',
                icono: <PlusOutlined />,
            },
            {
                nombre: 'Lista de Modelos',
                ruta: '/sistema/catalogo/modelos/lista',
                icono: <UnorderedListOutlined />,
            },
        ]
    },
    {
        nombre: 'Pedimentos (Exp. Docs)',
        ruta: '/sistema/catalogo/pedimentos',
        icono:  <FileOutlined />,
        submenu: true,
        submenuRutas: [
            // {
            //     nombre: 'Ver Pedimento',
            //     ruta: '/sistema/catalogo/pedimentos/busqueda',
            //     icono: <EyeOutlined />,
            // },
            {
                nombre: 'Nuevo Pedimento',
                ruta: '/sistema/catalogo/pedimentos/captura',
                icono: <PlusOutlined />,
            },
            {
                nombre: 'Lista de Pedimentos',
                ruta: '/sistema/catalogo/pedimentos/lista',
                icono: <UnorderedListOutlined />,
            },
        ]
    },
    {
        nombre: 'Mantenimientos (Maintenances)',
        ruta: '/sistema/catalogo/mantenimientos',
        icono:  <ToolOutlined />,
        submenu: true,
        submenuRutas: [
            // {
            //     nombre: 'Ver Mantenimiento',
            //     ruta: '/sistema/catalogo/mantenimientos/busqueda',
            //     icono: <EyeOutlined />,
            // },
            {
                nombre: 'Nuevo Mantenimiento',
                ruta: '/sistema/catalogo/mantenimientos/captura',
                icono: <PlusOutlined />,
            },
            {
                nombre: 'Lista de Mantenimientos',
                ruta: '/sistema/catalogo/mantenimientos/lista',
                icono: <UnorderedListOutlined />,
            },
        ]
    },
]

export default Rutas