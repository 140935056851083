import FormularioCliente from 'components/formularios/FormularioCliente';
import FormularioModelo from 'components/formularios/FormularioModelo';
import FormularioPedimento from 'components/formularios/FormularioPedimento';
import FormularioMantenimiento from 'components/formularios/FormularioMantenimiento';
import { FuncionAcomodarListaArrays } from 'functions/FuncHooks';
import moment from 'moment';

const CapturasConfig = {
    clientes: {
        capturaNombre: 'Cliente',
        FormularioComponente: FormularioCliente,
        api: 'clientes',
    },
    modelos: {
        capturaNombre: 'Modelos',
        FormularioComponente: FormularioModelo,
        api: 'modelos',
        acomodarDatosMostrar: data => FuncionAcomodarListaArrays(data, ['fotos', 'documentos'], ['cliente']),
        camposVaciarAlDuplicar: ['fotos', 'documentos'],
    },
    pedimentos: {
        capturaNombre: 'Pedimentos',
        FormularioComponente: FormularioPedimento,
        api: 'pedimentos',
        acomodarDatosMostrar: data => {
            return { ...FuncionAcomodarListaArrays(data, ['documentos', 'fotos'], ['modelo']), fecha: moment(data?.fecha) }
        },
        camposVaciarAlDuplicar: ['fotos', 'documentos'],
    },
    mantenimientos: {
        capturaNombre: 'Mantenimientos',
        FormularioComponente: FormularioMantenimiento,
        api: 'mantenimientos',
        acomodarDatosMostrar: data => {
            return { ...FuncionAcomodarListaArrays(data, ['fotos'], ['modelo']), fecha: moment(data?.fecha) }
        },
        camposVaciarAlDuplicar: ['fotos'],
    },
}

export { CapturasConfig }