import axios from 'axios';
import { message } from 'antd';
import Endpoints from 'configs/custom/Endpoints';

const MensajesError = {
    'ApplicationError': 'Cuenta bloqueada',
    'ValidationError': 'Credenciales Incorrectas',
    'UnauthorizedError': 'Sin Autorización'
}

const ValidarAcceso = async ({ data, cambiarCargando, cambiarUsuario, navigate }) => {
    cambiarCargando(true);
    const alertaEnProceso = message.loading('Validando credenciales...', 0)

    await axios({
        url: Endpoints.validacionAcceso,
        method: 'post',
        data: data
    })
        .then(response => {
            cambiarCargando(false)
            const { jwt, user } = response?.data
            cambiarUsuario({ ...user, token: jwt })
            alertaEnProceso()
            message.success('Acceso Correcto', 2)
            return navigate('/sistema')
        })
        .catch(err => {
            const { name } = err?.response?.data?.error
            alertaEnProceso()
            cambiarCargando(false)
            return message.error(MensajesError[name] ?? 'Ocurrió un problema', 3)
        });

}

const CerrarSesion = ({ cambiarUsuario, cambiarCargando }) => {
    cambiarCargando(true)
    setTimeout(() => {
        message.info('Sesión Cerrada', 3)
        cambiarUsuario(false)
    }, 1000);
}

const ModificarUsuario = async ({ data, usuario, cambiarCargando, cambiarUsuario }) => {
    cambiarCargando(true);
    const { token, id } = usuario
    const alertaEnProceso = message.loading('Guardando cambios...', 0)

    await axios({
        url: `${Endpoints.baseApi}/users/${id}`,
        method: 'put',
        headers: { 'Authorization': `Bearer ${token}` },
        data: data
    })
        .then(res => {
            alertaEnProceso()
            message.success('Guardado con Exito')
            cambiarCargando(false);
            const respuestaAxios = res.data;
            console.log(respuestaAxios);
            cambiarUsuario({ ...usuario, email: respuestaAxios.email, username: respuestaAxios.username })
        })
        .catch(() => {
            alertaEnProceso()
            message.error('Ocurrió un problema')
            cambiarCargando(false);
        })
}

const ValidarUsuario = async ({ cambiarUsuario, usuario, validarAlUsuario }) => {
    const { token } = usuario
    await axios({
        url: `${Endpoints.baseApi}/users/me`,
        method: 'get',
        headers: { 'Authorization': `Bearer ${token}` },
    })
        .then(response => {
            const { data } = response
            cambiarUsuario({ ...data, token })
            validarAlUsuario.current = false
        })
        .catch(err => {
            const { name } = err?.response?.data?.error
            cambiarUsuario(false)
            return message.info('Sesión Cerrada', 3)
        });

}

const SolicitarCodigoPassword = async ({ usuario, cambiarCargando }) => {
    cambiarCargando(true)
    const alertaEnProceso = message.loading('Enviando código por correo...', 0)

    const { token, email } = usuario
    await axios({
        url: `${Endpoints.baseApi}/auth/forgot-password`,
        method: 'post',
        headers: { 'Authorization': `Bearer ${token}` },
        data: { email }
    })
        .then(() => {
            alertaEnProceso();
            message.success('Código enviado', 5)
        })
        .catch(() => {
            alertaEnProceso();
            message.error('Ocurrió un problema, intentalo más tarde, o solicita apoyo al administrador.', 3)
        });
    cambiarCargando(false)
}

const CambiarPassword = async ({ data, usuario, cambiarCargando, cambiarUsuario }) => {
    cambiarCargando(true)
    const alertaEnProceso = message.loading('Cambiando contraseña...', 0)
    const { token } = usuario

    await axios({
        url: `${Endpoints.baseApi}/auth/reset-password`,
        method: 'post',
        headers: { 'Authorization': `Bearer ${token}` },
        data
    })
        .then(response => {
            cambiarCargando(false)
            const { jwt, user } = response?.data
            cambiarUsuario({ ...user, token: jwt })
            alertaEnProceso()
            message.success('Contraseña cambiada con éxito', 2)
        })
        .catch(err => {
            cambiarCargando(false)
            alertaEnProceso()
            return message.error('Ocurrió un error, solicita apoyo', 3)
        });

}

export { ValidarAcceso, CerrarSesion, ModificarUsuario, ValidarUsuario, SolicitarCodigoPassword, CambiarPassword }