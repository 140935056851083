import { useSearchParams } from 'react-router-dom';
import { GenerarObjetoDeFiltrosUrl } from 'functions/FuncFiltros';

const QueryTablaClientes = () => {
    
    let [searchParams] = useSearchParams();
    const { nombre, correo, page, pageSize } = GenerarObjetoDeFiltrosUrl(searchParams)
    
    return {
        // sort: sorter?.field && [`${sorter.field}:${sorter.order === 'descend' ? 'desc' : 'asc'}`],
        pagination: {
            page: page,
            pageSize: pageSize,
        },
        filters: {
            nombre: {
                $containsi: nombre,
            },
            $or: [
                {
                    correo: {
                        $containsi: correo,
                    }
                }
            ]
        },
    }
}

const QueryTablaModelos = () => {
    
    let [searchParams] = useSearchParams();
    const { identificador, nombre, cliente, page, pageSize } = GenerarObjetoDeFiltrosUrl(searchParams)
    
    return {
        // sort: sorter?.field && [`${sorter.field}:${sorter.order === 'descend' ? 'desc' : 'asc'}`],
        pagination: {
            page: page,
            pageSize: pageSize,
        },
        populate: '*',
        filters: {
            identificador: {
                $containsi: identificador,
            },
            $or: [
                {
                    nombre: {
                        $containsi: nombre,
                    }
                },
                
                {
                    cliente: {
                        nombre: {
                            $containsi: cliente,
                        }
                    }
                }
            ]
        },
    }
}

const QueryTablaPedimentos = () => {
    
    let [searchParams] = useSearchParams();
    const { numeroPedimento, clavePedimento, factura, page, pageSize } = GenerarObjetoDeFiltrosUrl(searchParams)
    
    return {
        // sort: sorter?.field && [`${sorter.field}:${sorter.order === 'descend' ? 'desc' : 'asc'}`],
        pagination: {
            page: page,
            pageSize: pageSize,
        },
        populate: '*',
        filters: {
            numeroPedimento: {
                $containsi: numeroPedimento,
            },
            $or: [
                {
                    clavePedimento: {
                        $containsi: clavePedimento,
                    }
                },
                {
                    factura: {
                        $containsi: factura,
                    }
                },
            ]
        },
    }
}

const QueryTablaMantenimientos = () => {
    
    let [searchParams] = useSearchParams();
    const { responsable, modelo, page, pageSize } = GenerarObjetoDeFiltrosUrl(searchParams)
    
    return {
        // sort: sorter?.field && [`${sorter.field}:${sorter.order === 'descend' ? 'desc' : 'asc'}`],
        pagination: {
            page: page,
            pageSize: pageSize,
        },
        populate: '*',
        filters: {
            modelo: {
                nombre: {
                    $containsi: modelo,
                },
            },
            $or: [
                {
                    responsable: {
                        $containsi: responsable,
                    }
                },
            ]
        },
    }
}

export { QueryTablaClientes, QueryTablaModelos, QueryTablaPedimentos, QueryTablaMantenimientos }