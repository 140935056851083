import React from 'react';
import { Input, Col, Form, Switch, Button, InputNumber, DatePicker, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Sizes from 'configs/custom/Sizes';

const InputTexto = ({ size, configElement, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <Input autoComplete="off" {...configElement} />
            </Form.Item>
        </Col>
    )
}

const InputPassword = ({ size, configElement, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <Input.Password {...configElement} autoComplete="off" />
            </Form.Item>
        </Col>
    )
}

const InputTextArea = ({ size, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} autoComplete="off" />
            </Form.Item>
        </Col>
    )
}

const InputSwitch = ({ size, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props} valuePropName="checked">
                <Switch />
            </Form.Item>
        </Col>
    )
}

const InputList = ({ name, label, size, tooltip, textoAgregar, inputDinero }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item label={label} tooltip={tooltip ?? false}>
                <Form.List name={name}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row gutter={20} key={key} style={{ display: 'flex', marginBottom: 24, width: '100%' }} align="middle">
                                    <Col span={11}>
                                        <Form.Item style={{ marginBottom: 0 }} {...restField} name={[name, 'nombre']}
                                            rules={[{ required: true, message: 'El nombre es requerido' }]} >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item style={{ marginBottom: 0 }} {...restField} name={[name, 'valor']}
                                            rules={[{ required: true, message: 'El valor es requerido' }]} >
                                            {
                                                inputDinero ?
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        autoComplete="off"
                                                        controls={false}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    />
                                                    :
                                                    <Input />
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {textoAgregar}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </Col>

    )
}

const InputDinero = ({ size, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <InputNumber
                    style={{ width: '100%' }}
                    autoComplete="off"
                    controls={false}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
            </Form.Item>
        </Col>
    )
}

const InputFecha = ({ size, ...props }) => {
    return (
        <Col {...Sizes[size]}>
            <Form.Item {...props}>
                <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
        </Col>
    )
}

export { InputTexto, InputSwitch, InputTextArea, InputList, InputDinero, InputFecha, InputPassword };
