import React, { useState } from 'react';
import { InputTexto, InputPassword } from 'core/other/Inputs';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { useAuth } from 'contexts/AuthContext';
import { ValidarAcceso } from 'functions/FuncUsuario';
import { ContenedorFormularioLogin, BotonIngresar } from 'components/login/Elementos';
import { useNavigate } from 'react-router-dom';
import { Form, Row } from 'antd';


const Login = () => {
    const [cargando, cambiarCargando] = useState(false);
    const { cambiarUsuario } = useAuth();
    let navigate = useNavigate();

    const onSumbitFunc = (data) => {
        ValidarAcceso({ data, cambiarCargando, cambiarUsuario, navigate })
    }

    return (
        <ContenedorFormularioLogin cargando={cargando}>
            <Form name="login" onFinish={onSumbitFunc}>
                <Row>
                    <InputTexto {...config['identifier']} />
                    <InputPassword {...config['password']} />

                    <BotonIngresar />
                </Row>
            </Form>
        </ContenedorFormularioLogin>
    );
}

export default Login;

const config = {
    identifier: {
        name: 'identifier',
        size: 'full',
        configElement: {
            placeholder: 'Usuario',
            prefix: <UserOutlined style={{ marginRight: 10 }} />,
        },
        rules: [
            { required: true }
        ]
    },
    password: {
        name: 'password',
        size: 'full',
        configElement: {
            placeholder: 'Contraseña',
            prefix: <UnlockOutlined style={{ marginRight: 10 }} />,
        },
        rules: [
            { required: true }
        ]
    },
}

