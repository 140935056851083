import useSWR from 'swr'
import { useAuth } from 'contexts/AuthContext';
import axios from 'axios'
import qs from 'qs';
import Endpoints from 'configs/custom/Endpoints';

const fetcher = options => axios(options).then(res => res.data)

const FetcherSwr = ({ query, endpoint }) => {
    const { usuario } = useAuth();
    
    const StringfyQuery = qs.stringify(query, { encodeValuesOnly: true })

    const options = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${usuario.token}` },
        url: endpoint ? `${Endpoints.baseApi}/${endpoint}?${StringfyQuery}` : null,
    };
    // console.log(options.url);

    const { data, error } = useSWR(options, fetcher)

    return {
        data,
        isLoading: !error && !data,
        isError: error
    }
}

export default FetcherSwr;