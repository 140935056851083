import React from 'react';
import { Card, Table, Form, Dropdown } from 'antd';
import { CatalogosConfig } from 'configs/custom/Catalogos';
import useObtenerListaTabla from 'hooks/useObtenerListaTabla';
import useValidarTipoUrl from 'core/custom/useValidarTipoUrl';
import FiltrosTabla from 'core/tables/FiltrosTabla';
import HeaderTabla from 'core/tables/elements/HeaderTabla';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { CambiarPaginacion, ModificarFiltrosUrl } from 'functions/FuncFiltros';
import useElementoSeleccionado from 'core/tables/elements/useElementoSeleccionado';
import MenuContextTable from 'core/tables/elements/MenuContextTable';
import useFormatearFormFiltros from 'core/tables/elements/useFormatearFormFiltros';

const TablaCatalogo = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [form] = Form.useForm();
    let [searchParams, setSearchParams] = useSearchParams();

    const { tipo } = useValidarTipoUrl({ ElementConfig: CatalogosConfig });
    // console.log('1');
    useFormatearFormFiltros({form, tipo})

    const { tablaColumnas, tablaConfigHook, tablaFiltros, tablaNombre } = CatalogosConfig[tipo] ?? {}
    const { elementoSeleccionado, cambiarElementoSeleccionado } = useElementoSeleccionado();

    const { data, meta, isLoading } = useObtenerListaTabla({ tablaConfigHook });

    return (
        <>
            <Card style={{ marginBottom: 28 }}>
                <Form layout="horizontal" form={form} hideRequiredMark onFinish={(data) => ModificarFiltrosUrl(data, setSearchParams)}>
                    <FiltrosTabla tablaFiltros={tablaFiltros} loading={isLoading} />
                </Form>
            </Card>
            <Card>
                <HeaderTabla
                    tablaNombre={tablaNombre}
                    form={form}
                    tipo={tipo}
                    elementoSeleccionado={elementoSeleccionado}
                    loading={isLoading}
                />
                <Dropdown overlay={MenuContextTable({ navigate, tipo, elementoSeleccionado, location })} trigger={['contextMenu']}>
                    <div>
                        <Table
                            columns={tablaColumnas}
                            rowKey={record => record.id}
                            dataSource={data}
                            loading={isLoading}
                            pagination={meta}
                            onChange={(pagination, filters, sorter) => CambiarPaginacion(pagination, searchParams, setSearchParams)}
                            rowSelection={{
                                type: 'radio',
                                preserveSelectedRowKeys: true,
                                selectedRowKeys: [elementoSeleccionado],
                                onSelect: (record) => cambiarElementoSeleccionado(record.id),
                            }}
                            onRow={(record) => {
                                return {
                                    onClick: event => cambiarElementoSeleccionado(record.id),
                                    onDoubleClick: event => navigate(`/sistema/catalogo/${tipo}/captura?id=${record.id}`, { state: { estatus: 'editando', origen: location.pathname + location.search } }),
                                    onContextMenu: event => cambiarElementoSeleccionado(record.id)
                                };
                            }}
                        />
                    </div>
                </Dropdown>
            </Card>
        </>
    );
}

export default TablaCatalogo;

