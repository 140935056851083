import { useState, useEffect } from 'react';
import Endpoints from 'configs/custom/Endpoints';
import { useAuth } from 'contexts/AuthContext';
import qs from 'qs';
import { message } from 'antd';
import useFiltrosUrl from 'core/custom/useFiltrosUrl';
import axios from 'axios';


const useObtenerDatosVista = ({ vistaConfigHook, cambiarCargando }) => {
    const { endpoint } = vistaConfigHook ?? {}
    const [data, cambiarData] = useState(null);
    const { token } = useAuth();
    const { id } = useFiltrosUrl();

    useEffect(() => {
        const PeticionDatos = async () => {
            if (id) {
                cambiarCargando(true)
                const query = qs.stringify({ populate: '*' }, { encodeValuesOnly: true, });
                await axios({
                    method: 'get',
                    url: `${Endpoints.baseApi}/${endpoint}/${id}?${query}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                    // body: qs.stringify({ populate: '*' }, { encodeValuesOnly: true, })
                })
                    .then(res => {
                        const { attributes, id } = res?.data?.data;
                        cambiarData({ ...attributes, id });
                        cambiarCargando(false)
                    })
                    .catch(err => {
                        console.log(err);
                        cambiarCargando(false)
                        cambiarData(false)
                        message.error('Si el problema continua, solicita apoyo al administrador', 5);
                    })
            } else {
                cambiarData(null)
                cambiarCargando(false)
            }
        }

        return PeticionDatos();
    }, [id])

    return { data }
}

export default useObtenerDatosVista;