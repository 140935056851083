import FetcherSwr from 'configs/tools/FetcherSwr';
import { FuncionAcomodarData } from 'functions/FuncHooks';

const useObtenerDatosSelect = ({ busqueda, camposBusqueda, endpoint, optSeleccionado }) => {

    const generarCamposBusqueda = () => {
        let campos = []

        camposBusqueda.forEach(element => {
            campos.push(
                {
                    [element]: {
                        $containsi: busqueda,
                    }
                }
            )
        });

        return [...campos]
    }

    const query = {
        sort: [camposBusqueda[0]],
        pagination: {
            page: 1,
            pageSize: 7,
        },
        filters: {
            $or: generarCamposBusqueda()
        },
    }

    const { data, isLoading, isError, mutate } = FetcherSwr({ query, endpoint });

    const newData = FuncionAcomodarData(data?.data);
    if (optSeleccionado && busqueda === '') {
        newData.push(optSeleccionado)
    }

    return { data: newData, isLoading, isError, mutate }
}

export default useObtenerDatosSelect;