import { useEffect } from 'react';

const useFormatearFormFiltros = ({ form, tipo }) => {
    useEffect(() => {
        const FormatearFormFiltros = () => {
            form.resetFields();
        }
        return FormatearFormFiltros();
    }, [tipo])
}

export default useFormatearFormFiltros;