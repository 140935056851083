import VistaCliente from 'components/vistas/VistaCliente';
import VistaModelos from 'components/vistas/VistaModelos';

const VistasConfig = {
    clientes: {
        vistaNombre: 'Cliente',
        ElementosVista: VistaCliente,
        vistaConfigSelect: {
            endpoint: 'clientes',
            camposBusqueda: ['nombre'],
        },
        vistaConfigHook: {
            endpoint: 'clientes',
        },
        
    },
    modelos: {
        vistaNombre: 'Modelos',
        ElementosVista: VistaModelos,
        vistaConfigSelect: {
            endpoint: 'modelos',
            camposBusqueda: ['identificador', 'nombre'],
            resultadoVista: (renglon) => `${renglon.identificador} - ${renglon.nombre}`
        },
        vistaConfigHook: {
            endpoint: 'modelos',
        },
        
    },
}

export { VistasConfig }

