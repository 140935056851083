import React, { useState } from 'react';
import { Select, Row, Col, Empty } from 'antd';
import useObtenerDatosSelect from 'hooks/useObtenerDatosSelect';
import Spinner from 'core/other/Spinner';
import useFiltrosUrl from 'core/custom/useFiltrosUrl';
import Sizes from 'configs/custom/Sizes';

const ElementoCargandoSelect = () => (<Row justify="center"><Spinner cargando={true} transparent={true} delay={300} /></Row>)

const SeleccionarElemento = ({ vistaConfigSelect }) => {
    const { endpoint, camposBusqueda, resultadoVista } = vistaConfigSelect
    const [busqueda, cambiarBusqueda] = useState('');
    const { id, modificarFiltrosUrl } = useFiltrosUrl();
    const { data, isLoading } = useObtenerDatosSelect({ busqueda, camposBusqueda, endpoint, idSeleccionado: id })

    return (
        <Row>
            <Col {...Sizes['full']}>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Buscar..."
                    filterOption={false}
                    showSearch
                    onSearch={cambiarBusqueda}
                    notFoundContent={isLoading ? <ElementoCargandoSelect /> : data.length === 0 ? <Empty /> : null}
                    onChange={(value) => modificarFiltrosUrl({ id: value })}
                >
                    {
                        data && data.map((renglon, index) => {
                            return (
                                <Select.Option value={renglon.id} key={index}>
                                    {resultadoVista ? resultadoVista(renglon) : renglon.nombre}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </Col>
        </Row>
    )
}

export default SeleccionarElemento;

