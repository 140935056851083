import React from 'react';
import { Tabs, Card, Typography, Space, Grid, Tag } from 'antd';
import FormularioCuenta from 'components/cuenta/FormularioCuenta';
import FormularioPassword from 'components/cuenta/FormularioPassword';
import styled from 'styled-components';
const { useBreakpoint } = Grid;

const TabConfig = () => {
    const screens = useBreakpoint();
    const { md } = screens

    return (
        <Card>
            <StyledSpace direction="vertical" size="large">
                <Typography.Title level={3}>Configuración</Typography.Title>
                <StyledTabs defaultActiveKey="1" tabPosition={md ? "left" : "top"}>
                    <Tabs.TabPane tab="Cuenta" key={1}>
                        <FormularioCuenta />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Contraseña" key={2}>
                        <FormularioPassword />
                    </Tabs.TabPane>
                </StyledTabs>
            </StyledSpace>
        </Card>
    );
}

export default TabConfig;

const StyledSpace = styled(Space)`
    padding: 15px 25px;
    width: 100%;
`

const StyledTabs = styled(Tabs)`
    width: 100%;
    min-height: 50vh;
`