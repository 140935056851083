const Sizes = {
    xsmall: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        xxl: 4
    },
    small: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
        xxl: 6
    },
    middle: {
        xs: 24,
        sm: 24,
        md: 8,
        lg: 8,
        xl: 8,
        xxl: 6
    },
    large: {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 12,
        xl: 12,
        xxl: 12
    },
    full: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        xxl: 24
    }
}

export default Sizes