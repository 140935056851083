import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popconfirm } from 'antd';

const ConfirmarSalir = ({ ModificaronFormulario, placement, children }) => {
    const [visible, cambiarVisible] = useState(false)
    let location = useLocation();
    let navigate = useNavigate();

    const confirm = () => {
        // TODO PENDIENTE VER SI HACEMOS ALGUNA MEJORA
        // console.log(location);
        cambiarVisible(false)
        if (location.state?.origen) {
            navigate(location.state.origen)
        } else {
            navigate(-1)
        }
    }

    const cancel = () => {
        cambiarVisible(false)
    };

    const handleVisibleChange = (handleVisible) => {
        if (!handleVisible) {
            return cambiarVisible(handleVisible);
        }

        if (ModificaronFormulario()) {
            cambiarVisible(handleVisible); // show the popconfirm
        } else {
            confirm(); // next step
        }
    };

    return (
        <Popconfirm
            title="¿Estás seguro de salir?"
            placement={placement}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            onConfirm={confirm}
            onCancel={cancel}
        >
            {children}
        </Popconfirm>
    );
}

export default ConfirmarSalir;